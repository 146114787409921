.wavesSeclect {
  margin-top: 15px;

}
.unmimitedBox {
  border: 4px solid #fbbc05;
  border-radius: 5px;
}
.unmimitedBox .lableShow{
  position: absolute;
  left: 0;
  bottom: 0;
  background: #FBBC05;
  color: #01243C;
  padding: 7px 20px;
  font-size: 16px;
  border-radius:0px 5px 0px 0px;
}
.dayPassBox {
  border: 4px solid #59A4EB;
  border-radius: 5px;
}
.dayPassBox .lableShow{
  position: absolute;
  left: 0;
  bottom: 0;
  background: #59A4EB;
  color: #fff;
  padding: 7px 20px;
  font-size: 16px;
  border-radius:0px 5px 0px 0px;
}

.wavesSeclect .btn{
  background: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  min-width: 300px;
  padding: 12px;
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
  font-family: "Exo 2", sans-serif;
  position: absolute;
  bottom: 10px;
}

.wavesSeclect .btn:hover{
  background: #fff;
  transition: .5s;
}

.wavesSeclect .btn:hover span, .wavesSeclect .btn:hover b{
  color: #102D48;
  transition: .5s;
}

.wavesSeclect .btn:hover img{
  filter: invert(91%) sepia(62%) saturate(1553%) hue-rotate(184deg) brightness(91%) contrast(90%);
}

.wavesSeclect .btn span{
    color: #fff;
    font-size: 34px;
    font-weight: 700;
    font-style: italic;
}

.wavesSeclect .btn b{
  color: #59A4EB;
}

.wavesSeclect .btn img{
  width: 27px;
}

.HeadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.header {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
}
.HeaderFirst {
  flex: 0 0 150px;
  justify-content: start !important;
  padding-left: 0px !important;
}
.HeaderLast {
  flex: 0 0 150px;
}

.locationWrapper {
  display: flex;
  row-gap: 1rem;
  column-gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
}

.LocationRender-background {
  position: absolute;
  background: rgba(18, 24, 33, 0.41);
  top: 0;
  bottom: 0;
  width: 100%;
}

.LocationRender-background-mouseOver {
  background: rgba(18, 24, 33, 0)
}

.LocationRender-label {
  top: 20%;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;

}

.LocationRender-sponsor {
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 2rem;
  position: absolute;
  height: 25%;

}

.LocationRender-mouse-shim {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
}

.LocationRender-wrapper {
  /* flex: 1 1 50%; */
  display: flex;
  justify-content: center;
  width: 424px;
  height: 262px;
  /* width: 25%; */
  position: relative;
}

.LocationRender-wrapper-img {
  margin: 10px 14px 10px 14px;


  width: 396px;
  height: 242px;
}



.button-title-btn {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: 'exo 2', sans-serif !important;
  color: white !important;
  font-size: 1.1rem !important;
  align-self: self-start !important;
}

.button-title-btn{
    background-color: transparent !important;
}


button.btnColorChange{
  background-color: #234A6D !important;
}

button.btnColorChange:hover{
  background-color: #fff !important;
  color: #234A6D;
}

@media screen and (max-width : 767px) {
  .wavesSeclect .btn {
    min-width: 218px;
    font-size: 17px;
    padding: 6px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    height: 55px;
  }
  .wavesSeclect .btn img{
    width: 22px;
  }
  .wavesSeclect .btn span{
    font-size: 30px;
  }
}

@media screen and (max-width : 550px) {
  .button-title-btn {
    font-size: 1.2rem !important;
  }

  .wavesSeclect {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 0px;
    background: #01243c;
    width: 100%;
    padding: 15px;
    height: 75px;
  }

  :global(.MuiGrid-item){
    padding-top: 0px !important;
  }

  .wavesSeclect .btn{
    margin: 0 auto;
  }
}


.date_time_button {
  width: 100%;
  margin-bottom: 8px !important;
  border-radius: 0 !important;
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  padding: 1rem 0 !important;
  background-color:  rgba(18, 24, 33, 0.41) !important;
  font-family: 'exo 2', sans-serif !important;
  display: flex;
  flex-direction: column;

}

@media screen and (max-width : 550px) {
  .date_time_button {
    font-size: 1.2rem !important;
    padding: 0.6rem 0 !important;
  }
}

/* .forkongithub a:hover {
  background: #c11;
  color: #fff;
} */

/* .forkongithub a::before,
.forkongithub a::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
  height: 1px;
  background: #fff;
}

.forkongithub a::after {
  bottom: 1px;
  top: auto;
} */

.banner {
  position: absolute;
  display: block;
  top: 0s0px;
  right: 0;
  width: 200px;
  overflow: hidden;
  height: 200px;
}

.banner a {
  background: #D33F49;
  color: #fff;
  text-decoration: none;
  font-family: arial, sans-serif;
  text-align: center;
  font-weight: bold;
  padding: 5px 40px;
  font-size: 1rem;
  line-height: 2rem;
  position: relative;
  transition: 0.5s;

  width: 200px;
  position: absolute;

  top: 20px;
  right: -60px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);

}



@media screen and (max-width : 800px) {
  .LocationRender-wrapper {
    width: 100%;
    height: 180px;
    padding: 10px 10px;
  }
  .topSpace {
    padding-top: 0px !important;
  }
  .LocationRender-wrapper-img {
    width: 100%;
    height: 150px;
    margin: 0;
  }
  .LocationRender-label {
    font-size: 1.2rem;
  }
  .header {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
  }
  .HeadingContainer {
    display: block;
  }
  .HeaderFirst {
    flex: 0 0 120px;
  }
  .HeaderLast {
    flex: 0 0 10px;
  }
}


/* :global(.css-1tj2k35-MuiButtonBase-root-MuiButton-root.css-1tj2k35-MuiButtonBase-root-MuiButton-root) {
  background-color: #102D48;
}

:global(.css-1tj2k35-MuiButtonBase-root-MuiButton-root.css-1tj2k35-MuiButtonBase-root-MuiButton-root:hover){
  background-color: #fff !important;
  transition: .5s;
  color: #102d48;
} */

.HeadingContainer button:first-child{
  background-color: transparent !important;
}