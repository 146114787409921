@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
@import "./base.scss";

@layer tw-components {
  @tailwind components;
  @layer tw-base;
}
@layer tw-utilities {
  @tailwind utilities;
}
@layer tw-global {
  html,
  body,
  main,
  #__next {
    min-height: 100%;
    width: 100%;
  }

  :root {
    --font-exo: "Exo 2", sans-serif;

    --brand-primary: 1, 36, 60; /* #01243C */

    --brand-light: 248, 248, 248; /* #F8F8F8 */

    --brand-green: 0, 126, 51; /* #007E33 */
    --brand-lemon-green: 0, 200, 81; /* #00C851 */
    --brand-dark-green: 0, 128, 128; /* #008080 */
    --brand-pale-blue: 134, 169, 197; /* #86A9C5 */
    --brand-yellow: 250, 186, 6; /* #FABA06 */
    --brand-orange: 255, 158, 47; /* #FF9E2F */
    --brand-red: 233, 27, 27; /* #E91B1B */
    --brand-red-2: 234, 45, 45; /* #EA2D2D */
    --brand-red-3: 255, 68, 68; /* #F44 */
    --brand-grey: 217, 217, 217; /* ##d9d9d9 */

    --brand-shadow-100: -4.5px -2.3px 8.5px 0px rgba(0, 0, 0, 0.25);
    --brand-shadow-200: 0px -9px 59px 0px rgba(0, 0, 0, 0.25);
    --brand-shadow-300: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
    --brand-shadow-400: 0px 4.6px 14px 0px rgba(0, 0, 0, 0.17);
    --brand-shadow-500: 0px 4.7px 4.7px 0px rgba(0, 0, 0, 0.05);
    --brand-shadow-600: 0px 6px 6px 0px rgba(0, 0, 0, 0.05);
    --brand-shadow-700: 0px 8px 28px 0px rgba(0, 0, 0, 0.1);
    --brand-shadow-800: 0px 11px 38px 0px rgba(0, 0, 0, 0.1);
    --brand-shadow-900: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    --brand-shadow-1000: 0px -9px 59px 0px rgba(0, 0, 0, 0.25);
    --brand-shadow-1100: 3px 1.7px 6.2px 0px rgba(0, 0, 0, 0.25);
    --brand-shadow-1200: 0 12px 24px 0px rgba(0, 0, 0, 0.1);

    --brand-radius-100: 23px;
    --brand-radius-200: 38px;
    --brand-radius-300: 48px;
    --brand-radius-400: 50px;
    --brand-radius-500: 60px;
    --brand-radius-600: 64px;
    --brand-radius-700: 66px;

    --brand-font-size-md: 1rem;
    --brand-font-size-2xl: 2.125rem;
    --brand-font-size-3xl: 2.5rem;

    --brand-width-fit: fit-content;
    --brand-max-width-section: 80.625rem;
    --brand-max-width-md: 27rem;

    --brand-background: var(--brand-light);
    --brand-foreground: var(--brand-primary);

    --brand-border-gradient-primary-mobile: linear-gradient(
      295deg,
      rgba(0, 128, 128, 1) 0%,
      rgba(248, 248, 248, 1) 44%,
      rgba(248, 248, 248, 1) 56%,
      rgba(0, 128, 128, 1) 100%
    );

    --brand-border-gradient-primary-desktop: linear-gradient(
      308deg,
      rgba(0, 128, 128, 1) 0%,
      rgba(248, 248, 248, 1) 44%,
      rgba(248, 248, 248, 1) 56%,
      rgba(0, 128, 128, 1) 100%
    );
  }

  body {
    font-family: var(--font-exo);
    background-color: rgb(var(--brand-background));
    color: rgb(var(--brand-foreground));
    overflow-x: hidden;
  }

  button,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: italic;
  }

  .border-brand-gradient-primary {
    &::after {
      content: "";
      position: absolute;
      inset: -2px;
      inset-inline: -3px;
      background: var(--brand-border-gradient-primary-mobile);
      z-index: -1;
      border-radius: inherit;

      @media (min-width: 48rem) {
        inset: -3px;
        inset-inline: -4px;
        background: var(--brand-border-gradient-primary-desktop);
      }
    }
  }

  .writing-v-rl {
    writing-mode: vertical-rl;
  }

  .writing-v-lr {
    writing-mode: vertical-lr;
  }
}

.twp {
  @layer tw-components, tw-utilities, tw-global;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .border-b-2 {
    border-bottom: 2px solid;
  }

  .fade-bg {
    background: linear-gradient(90deg, #01243c 64%, rgba(1, 36, 60, 0) 91.07%);
    width: 75px;
  }

  .rotated-badge-primary {
    transform: translateX(-4px) rotate(-90deg);
    transform-origin: bottom;
  }

  .rotated-badge-secondary {
    transform: translateX(-12px) rotate(-90deg);
    transform-origin: bottom;

    @media (min-width: 48rem) {
      transform: translateX(-24px) rotate(-90deg);
    }
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
}
