.checkout {
  padding-top: 1rem;
  overflow-y: scroll;

  padding-inline: 1rem;
  max-height: 100vh;
  background: #01243c;
  font-style: italic;
  width: 100%;
  @media (min-width: 46rem) {
    overflow-y: scroll;
  }

  @media (min-width: 60rem) {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}

.checkout p {
  margin: 0;
}

.checkout p {
  cursor: pointer;
}
