.footer {
  background: #01243C;
  color: white;
  // border-radius: 50px 50px 0px 0px;
  // margin-top: -50px;

  &-container {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;

    
    @media screen and (max-width: 600px) {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }
  &__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 991px) {
      text-align: center;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      padding: 0px 18px;

      .mobileNumber{
        font-size: 12px !important;
        justify-content: flex-start !important;
      }

      .footerSize{
        width: 50%;
        text-align: left;
        margin-bottom: 12px;
        font-size: 12px;

        a{
          display: flex;
          justify-content: center;
          font-size: 28px;
        }

        &.order-1{
          order: -1;
        }
        &.order1{
          order: 1;
        }
      }
    }
    &__contact {
      &-heading {
        font-size: 1.15rem;
        font-weight: 700;
        font-style: italic;
        text-transform: uppercase;
      }
      &-item {
        font-weight: 400;
        font-size: italic;
        margin-top: 0.9rem;
        
      @media screen and (max-width: 600px) {
        margin-top: 8px;
      }
      }
    }
    &__social {
      display: flex;
      flex-direction: column;
      // align-items: center;

      @media screen and (max-width: 600px) {
        align-items: flex-start;
        margin-top: 2rem;
        text-align: center;
        align-items: center;
      }

      &-heading {
        font-size: 1.15rem;
        font-weight: 700;
        text-transform: uppercase;
        font-style: italic;

        @media screen and (max-width: 1024px) {
          text-align: center;
        }
      }

      &-links {
        margin-top: 0.8em;
        display: flex;
        align-items: center;
        &-link {
          font-size: 2.5rem;
          margin-left: 1rem;
          &:first-child {
            margin-left: 0rem;
          }
        }

        @media screen and (max-width: 1024px) {
          justify-content: center;
        }
      }
    }

    &__companyinfo-heading{
      margin-bottom: 12px;
      
      @media screen and (max-width: 600px) {
        margin-bottom: 8px;
      }
    }

    &__companyinfo-item{
      margin-bottom: 12px;
        @media screen and (max-width: 600px) {
          margin-bottom: 8px;
        }
    }
  }
  &-links {
    display: flex;

    @media screen and (max-width: 900px) {
      .temp {
        display: none;
      }
      width: 100%;
    }
    @media screen and (max-width: 850px) {
      width: 85%;
    }
    @media screen and (max-width: 590px) {
      width: 100%;
      font-size: 0.9rem;
      flex-direction: column;
      margin-top: 1.6rem;
      text-align: center;
      .temp {
        display: none;
      }
    }
    margin: auto;
    margin-top: 0px;
    text-transform: uppercase;
    font-weight: 500;
    font-style: italic;
    justify-content: space-between;
    @media screen and (min-width: 590px) {
      &__link {
        margin-left: 0rem;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .bottomFooter{
    box-shadow: 0px -9px 59px 0px #00000040;
    padding: 25px 0px;
  }
}


.footer__logo__ft img{
   height: 80px;
}



@media screen and (max-width: 600px) {

  .footer__logo__ft img{
    margin: -10px 0px 20px;
    height: 32px;
 }

 .bottomFooter{
  display: none;
}
}