.checkoutContainer {
  position: relative;
  max-width: 490px;
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 80px;

  @media (min-width: 60rem) {
    max-width: 960px;
  }
}

.checkoutInnerContainer {
  color: black;
}

.checkoutPlans {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  width: 100%;
  position: relative;
  max-width: 490px;

  @media (min-width: 60rem) {
    gap: 24px;
    z-index: 100;

  }
}

.checkoutPlanItem {
  padding-inline: 24px;
  padding-block: 20px;
  border-radius: 16px;
  background: white;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  cursor: pointer;
  transition: all 0.15s ease;

  @media (min-width: 60rem) {
    gap: 8px;
    border-radius: 24px;
    padding-block: 20px 14px;
    padding-inline: 28px;
  }
}

/*.checkoutPlanItem:hover {*/
/*  outline: 4px solid rgba(34, 83, 245, 0.8);*/

/*  @media (min-width: 60rem) {*/
/*    outline: 6px solid rgba(34, 83, 245, 0.8);*/
/*  }*/
/*}*/

.checkoutPlanItemActive {
  outline: 4px solid transparent;
  outline-offset: -4px;

  @media (min-width: 60rem) {
    outline: 6px solid #faba06;
    outline-offset: -6px;
  }
}

.checkoutPlanItemHighlightedActive {
  outline-color:  #faba06
}

.checkoutPlanItemNormalActive {
  outline-color:  rgba(34, 83, 245, 0.8);
}

.checkoutPlanItemTitle {
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700;

  @media (min-width: 60rem) {
    font-size: 1.875rem;
  }
}

.checkoutPlanItemDescription {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: normal;

  @media (min-width: 60rem) {
    font-size: 1rem;
  }
}
.checkoutPlanItemInfoText {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 60rem) {
    font-size: 0.75rem;
  }
}

.checkoutPlanItemPriceContainer {
  display: flex;
  flex-direction: column;
}

.checkoutPlanItemPrice {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  font-weight: 700;
  margin-top: -14px;
  text-transform: uppercase;
  position: relative;
}

.checkoutPlanItemPriceValue {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
  font-size: 1.5rem;

  @media (min-width: 60rem) {
    font-size: 1.875rem;
  }
}

.checkoutPlanItemCrossedValue {
  position: relative;
  font-size: 1.25rem;

  @media (min-width: 60rem) {
    font-size: 1.5rem;
  }
}
.checkoutPlanItemCrossedValue::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  transform: translateY(-50%) rotate(-20deg);
  inset: 2px;
  top: 50%;
  background: #da1414;
}



.checkoutPlanItemLimitedOffer {
  position: absolute;
  bottom: calc(100% + 3px);
  right: 0;
  font-size: 0.5rem;
  padding: 6px 10px;
  font-weight: 600;
  color: white;
  text-transform: none;
  border-radius: 9999px;
  background: #2253F5;
}

.checkoutPlanItemPricePeriod {
  font-size: 1rem;
}

.checkoutPlanDetails {
  border-radius: 16px;
  background: white;
  padding: 16px 28px;

  width: 100%;

  @media (min-width: 60rem) {
    border-radius: 24px;
    padding: 30px 50px;
    position: absolute;
    top: 50%;
    right: 0;
    max-width: 609px;
    transform: translateY(calc(-50% - 40px));
  }
}

.checkoutPlanDetailsInnerContainer {
  @media (min-width: 60rem) {
    padding-left: 100px;
  }
}

.checkoutPlanDetailsFirstContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #80808042;

  @media (min-width: 60rem) {
    gap: 20px;
    padding-bottom: 30px;
  }
}

.checkoutPlanDetailsTitle {
  font-size: 1.25rem;
  font-weight: 600;

  @media (min-width: 60rem) {
    font-size: 1.5rem;
  }
}
.checkoutPlanDetailsItemContainer {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 5px;

  @media (min-width: 60rem) {
    gap: 10px;
  }
}
.checkoutPlanDetailsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;

  @media (min-width: 60rem) {
    font-size: 1.25rem;
  }
}

.checkoutPlanDetailsTotalItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 600;

  @media (min-width: 60rem) {
    font-size: 1.5rem;
  }
}

.checkoutPlanDetailsDiscountItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 600;
  padding-top: 20px;
  cursor: pointer;

  @media (min-width: 60rem) {
    font-size: 1.5rem;
    padding-top: 30px;
  }
}

.checkoutDetailsDropdownButton {
  background: transparent;
  border: none;
}
.chevronIcon {
  transition: all 0.3s ease;
}

.chevronIconActive {
  transform: rotate(180deg);
}

/*.input {*/
/*  border-radius: 12px;*/
/*  padding: 10px;*/
/*  border: 2px solid #b2b2b2;*/
/*  font-size: 1rem;*/
/*  width: 100%;*/
/*  margin-top: 32px;*/
/*  font-weight: 600;*/

/*  @media (min-width: 60rem) {*/
/*    padding: 14px;*/
/*  }*/
/*}*/

.checkoutDiscountForm {

}

.checkoutDiscountFormInput {
  border-radius: 12px;
  padding: 10px;
  border: 2px solid;
  border-color: #b2b2b2;
  font-size: 1rem;
  width: 100%;
  margin-top: 32px;
  font-weight: 600;

  @media (min-width: 60rem) {
    padding: 14px;
  }
}

.checkoutDiscountFormSuccessInput {
  border-color: #287D3C;
}

.checkoutDiscountFormErrorInput {
  border-color: #DA1414;
}

.checkoutDiscountFormMessage {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
}

.checkoutDiscountFormSuccessMessage {
  color: #287D3C
}

.checkoutDiscountFormErrorMessage {
  color: #DA1414
}
