.marginTopHighLight {
  margin-top: -520px;
  margin-bottom: 520px;
}

.howItWorks {
  padding: 0px 0px 30px;
  background-color: #f8f8f8;

  .hotItHeading {
    padding-top: 90px;

    .topTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0px;

      h2 {
        font-size: 32px;
        line-height: 42px;
        font-weight: 700;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;

        span {
          color: #86a9c5;
        }
      }

      h5 {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;
      }
    }

    .stepsfive {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      gap: 10px;

      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;
      }

      h4 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #86a9c5;
        font-family: "Exo 2";
        font-style: italic;
      }
    }
  }

  .tabsAnimation {
    .css-19kzrtu {
      padding: 0px 0px 0px;
    }

    .MuiTabs-scroller {
      max-width: 1000px;
      margin: 0 auto;
    }

    .tabsOne {
      .content {
        text-align: center;
        height: 170px;
        position: relative;
        margin-top: 40px;

        h3 {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          margin-bottom: 10px;
          text-transform: uppercase;
          color: #86a9c5;
          font-family: "Exo 2";
          font-style: italic;
        }

        h4 {
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          margin-bottom: 0px;
          color: #01243c;
          font-family: "Exo 2";
          font-style: italic;
        }

        .btn {
          text-decoration: none;
          background-color: #01243c;
          border: 1px solid #01243c;
          border-radius: 50px;
          color: #fff;
          padding: 15px 30px;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          font-family: "Exo 2";
          font-style: italic;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px auto 0px;
          position: absolute;
          left: 0;
          transform: translate(50px, 0px);
          bottom: 35px;
          width: 202px;
          cursor: pointer;
        }
      }

      .tobsBottom {
        text-align: center;

        img {
          width: 70%;
        }

        video {
          width: 70%;
          border-radius: 8px;
        }
      }
    }

    .tabsTwo {
      .content {
        text-align: center;
        height: 170px;
        position: relative;
        margin-top: 40px;

        h3 {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          margin-bottom: 12px;
          text-transform: uppercase;
          color: #86a9c5;
          font-family: "Exo 2";
          font-style: italic;
        }

        h4 {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          margin-bottom: 0px;
          color: #000000;
          font-family: "Exo 2";
          font-style: italic;
        }

        .btn {
          text-decoration: none;
          background-color: #01243c;
          border: 1px solid #01243c;
          border-radius: 50px;
          color: #fff;
          padding: 15px 30px;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          font-family: "Exo 2";
          font-style: italic;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 26px auto 0px;
          position: absolute;
          left: 0;
          transform: translate(50px, 0px);
          bottom: 0px;
          width: 202px;
        }
      }

      .tobsBottom {
        text-align: center;

        img {
          width: 100%;
        }

        video {
          width: 100%;
        }
      }
    }

    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        display: flex;
        justify-content: space-between;

        button {
          width: 20%;
          position: relative;
          overflow: initial;

          &:nth-child(1) {
            &:before {
              background-image: url(../../assets/LandingNew/WaveDesign.svg);
              position: absolute;
              content: "";
              height: 60px;
              width: 160px;
              background-repeat: no-repeat;
              right: 0px;
              background-size: cover;
              top: 7px;
              transform: translate(50%, 0%);
            }
          }

          &:nth-child(2) {
            &:before {
              background-image: url(../../assets/LandingNew/WaveDesign.svg);
              position: absolute;
              content: "";
              height: 60px;
              width: 160px;
              background-repeat: no-repeat;
              right: 0px;
              background-size: cover;
              top: 10px;
              transform: translate(-50%, -100%);
              rotate: 180deg;
            }
          }

          &:nth-child(3) {
            &:before {
              background-image: url(../../assets/LandingNew/WaveDesign.svg);
              position: absolute;
              content: "";
              height: 60px;
              width: 160px;
              background-repeat: no-repeat;
              right: 0px;
              background-size: cover;
              top: 7px;
              transform: translate(50%, 0%);
            }
          }

          &:nth-child(4) {
            &:before {
              background-image: url(../../assets/LandingNew/WaveDesign.svg);
              position: absolute;
              content: "";
              height: 60px;
              width: 160px;
              background-repeat: no-repeat;
              right: 0px;
              background-size: cover;
              top: 10px;
              transform: translate(-50%, -100%);
              rotate: 180deg;
            }
          }

          .tabsTopDesign {
            font-size: 17px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            color: #01243c;
            font-family: "Exo 2";
            font-style: italic;

            .round {
              width: 85px;
              height: 85px;
              border-radius: 50%;
              border: 3px solid #01243c;
              padding: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 24px;
              background: #f8f8f8;
              position: relative;

              img {
                display: none;
                height: auto;
                width: 74px;
              }

              span {
                font-size: 50px;
                font-weight: 700;
                line-height: 105px;
                text-transform: uppercase;
                color: #01243c;
                font-family: "Exo 2";
                font-style: italic;
              }
            }
          }

          .tabsHeight {
            min-height: 65px;
          }

          &.Mui-selected {
            .tabsTopDesign {
              font-size: 22px;
              line-height: 28px;

              .round {
                width: 134px;
                height: 134px;
                box-shadow: 0px 3px 13px 0px #01243c3d;
                box-shadow: 0px 4px 10px 0px #01243c3d inset;
                border: 4px solid;

                img {
                  display: block;
                }

                span {
                  display: none;
                }
              }
            }
          }

          &:focus {
            box-shadow: none;
            border: none;
            outline: none;
          }
        }
      }

      .MuiTabs-indicator {
        display: none;
      }
    }

    .tabsBottomButton {
      text-align: center;
      margin-top: -15px;

      .btn {
        text-decoration: none;
        background-color: #01243c;
        border: 1px solid #01243c;
        border-radius: 50px;
        color: #fff;
        padding: 10px 80px;
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        font-family: "Exo 2";
        font-style: italic;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 10px;
        cursor: pointer;
      }

      h5 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;
      }

      a {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;
        text-decoration: underline;
      }
    }
  }

  .pin-spacer {
    padding: 20px !important;
  }
}

.joinBox {
  padding-bottom: 50px;
  padding-top: 30px;
  background-color: #f8f8f8;

  .boxDesign {
    background-color: #01243c;
    padding: 30px 60px;
    border-radius: 40px;
    height: 100%;

    span {
      font-size: 16px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 0px;
      text-transform: uppercase;
      color: #a1c5e1;
      font-family: "Exo 2";
      font-style: italic;
      display: inline-block;
    }

    h3 {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      font-family: "Exo 2";
      font-style: italic;
      color: #fff;
      margin-bottom: 3px;
      text-transform: uppercase;
    }

    .userList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #ffffff;
        font-family: "Exo 2";
        font-style: italic;
      }

      .userImg1 {
        display: none;
      }

      .imgSize {
        img {
          width: auto !important;
          height: 75px !important;
        }
      }

      .userImg {
        display: flex;
        align-items: center;

        img {
          width: 75px;
          height: 75px;
          border-radius: 50px;
          object-fit: fill;

          &:nth-child(2) {
            transform: translate(-20px, 0px);
          }

          &:nth-child(3) {
            transform: translate(-40px, 0px);
          }
        }
      }
    }
  }
}

.videosAnywhare {
  padding-bottom: 70px;
  background-color: #f8f8f8;

  .titleHeading {
    text-align: center;
    margin-bottom: 40px;

    h3 {
      font-size: 32px;
      line-height: 42px;
      font-weight: 700;
      margin-bottom: 0px;
      font-family: "Exo 2";
      font-style: italic;
      color: #01243c;
      text-transform: uppercase;
    }
  }

  .videoBox {
    .subHeading {
      text-align: center;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      gap: 90px;
      margin-left: 40px;

      h5 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0px;
        color: #000000;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
      }

      p {
        color: #86a9c5;
        font-size: 18px;
        margin-bottom: 0px;
        font-weight: 400;
        line-height: 36px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
      }
    }
  }

  .videoModal {
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px #00000040;
    padding: 50px 42px;
    border-radius: 50px;
    position: relative;

    .imgweb {
      margin-bottom: 20px;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(40%, -44%);

      .laptopVideoMargeWeb {
        position: relative;

        video {
          width: 377px;
          position: absolute;
          left: 50%;
          top: 15px;
          transform: translate(-50%, 0%);
          height: 236px;
          object-fit: fill;
        }
      }

      .laptopVideoMargeMobile {
        display: none;

        .mobileimg {
          display: none;
        }
      }

      img {
        width: 510px;
      }
    }

    ul {
      padding-left: 0px;
      list-style: none;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      padding-right: 310px;

      li {
        display: flex;
        align-items: center;
        gap: 17px;
        padding: 14px 0px;

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          font-family: "Exo 2";
          font-style: italic;
          color: #01243c;
          text-transform: uppercase;

          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

.locationSection {
  padding-top: 85px;
  padding-bottom: 95px;
  background-color: #f8f8f8;

  .locationContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    h3 {
      font-size: 32px;
      line-height: 42px;
      font-weight: 700;
      margin-bottom: 0px;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      color: #01243c;
    }

    .TapsButton {
      border-radius: 80px;
      border: 3px solid #01243c;
      width: 165px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        font-size: 18px;
        background-color: transparent;
        width: 50%;
        border: none;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        color: #7b8d9a;
        font-weight: 700;
        height: 100%;
        cursor: pointer;
        min-width: 51%;

        &.active {
          background-color: #01243c;
          color: #fff;
          box-shadow: -9.876505851745605px -4.9553399085998535px 18.526315689086914px
            0px #00000040;
          border-radius: 80px;
        }
      }
    }
  }

  .mapLocation {
    position: relative;
    border-radius: 25px;

    .mapViewImg {
      overflow: hidden;
      border-radius: 24px;

      .mapImg {
        height: 420px;
        width: 100%;
        object-fit: cover;
        border-radius: 24px;
      }

      .map {
        height: 420px;
        width: 100%;
        object-fit: cover;
        border-radius: 24px;

        &:focus {
          outline: none;
        }
      }

      .mapContent {
        background-color: #fff;
        padding: 8px 34px;
        border-radius: 24px 0px 34px 0px;
        box-shadow: 4.9714288712px 6.6285715103px 11.2685718536px 0px
          rgba(0, 0, 0, 0.1882352941);
        position: absolute;
        left: 0px;
        top: 0px;

        h5 {
          font-size: 28px;
          font-weight: 700;
          line-height: 34px;
          color: #01243c;
          font-family: "Exo 2";
          font-style: italic;
          margin-bottom: 0px;
        }
      }
    }
  }

  .mapBox {
    margin-top: -90px;
    display: flex;
    align-items: self-start;
    gap: 16px;
    justify-content: space-between;

    .box {
      background-color: #fff;
      border-radius: 36px;
      height: 230px;
      width: 236px;
      text-align: center;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.active {
        transition: 0.5s;
        transform: scale(1.05);

        .topImage {
          display: block;
        }
      }

      .topImage {
        margin: -100px auto 0px;
        margin-bottom: 20px;
        width: auto;
        height: auto;
        display: none;
      }

      .bottomImage {
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 50%);
        width: auto;
        height: auto;
      }

      h3 {
        font-size: 25px;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 7px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        color: #01243c;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        color: #01243c;
      }

      span {
        background-color: #01243c;
        border-radius: 50px;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 10px 20px;
        line-height: 20px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .owl-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0px;

      .owl-next {
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 238px;
          height: 238px;
          top: 50%;
          right: 64px;
          transform: translate(100%, -50%);
          z-index: -1;
          border-radius: 36px;
          background: linear-gradient(
            270deg,
            #f8f8f8 34.55%,
            rgba(255, 255, 255, 0) 224.91%
          );
        }
      }

      .owl-prev {
        &:before {
          background: linear-gradient(
            90deg,
            #f8f8f8 34.55%,
            rgba(255, 255, 255, 0) 224.91%
          );
          border-radius: 36px;
          content: "";
          position: absolute;
          display: block;
          width: 238px;
          height: 240px;
          top: 50%;
          left: 64px;
          transform: translate(-100%, -50%);
          z-index: -1;
        }
      }

      .owl-next {
        position: absolute;
        right: -85px;
      }

      .owl-prev {
        position: absolute;
        left: -80px;
      }

      .owl-prev,
      .owl-next {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background-color: #01243c;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        margin: 0px;

        svg {
          font-size: 28px;
          color: #fff;
        }
      }

      .disabled {
        opacity: 0;
      }
    }

    .owl-stage-outer {
      overflow: visible;

      .owl-stage {
        .active {
          .box {
            box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 7%);
          }
        }
      }
    }
  }
}

.pricingSection {
  padding-bottom: 40px;
  padding-top: 70px;
  background-color: #f8f8f8;

  .content {
    margin-bottom: 30px;

    h2 {
      font-size: 32px;
      font-weight: 700;
      color: #01243c;
      margin-bottom: 0px;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
    }
  }

  .paddingLeft {
    padding-left: 70px;
  }

  .boxPricingTop {
    padding: 30px 30px;
    border-radius: 40px;
    position: relative;
    z-index: 1;
    animation: 500ms fadeIn ease-in-out;

    &.one {
      border: 8.75px solid #faba06;
      background: #01243c;
    }

    &.two {
      background: #86a9c5;
      border: 8.75px solid #86a9c5;
    }

    &.three {
      background: #2b485c;
      border: 8.75px solid #2b485c;
    }

    .title {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        color: #fff;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 0px;
        font-weight: 600;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;

        span {
          color: #faba06;
        }
      }

      svg {
        color: #fff;
        font-size: 30px;
      }
    }

    p {
      min-height: 136px;
      max-height: max-content;

      span {
        display: block;
        color: #fff;
        padding-bottom: 12px;
        font-size: 19px;
        font-weight: 500;
        font-family: "Exo 2";
        font-style: italic;
        line-height: 22px;
      }
    }

    .priceRate {
      margin-top: -26px;

      h4 {
        font-size: 46px;
        font-weight: 700;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 0px;
        color: #fff;
        text-align: right;

        span {
          font-size: 19px;
          font-weight: 500;
          text-transform: lowercase;
        }
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(1.5rem);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .smallBox {
    box-shadow: 0px 12.609720230102539px 44.13401794433594px 0px #0000001a;
    background-color: #fff;
    border-radius: 75px;
    padding: 130px 60px 55px;
    margin-top: -120px;
    position: relative;

    .list {
      ul {
        list-style: none;
        padding-left: 160px;
        margin-bottom: 0px;

        li {
          display: flex;
          align-items: center;
          gap: 18px;
          padding: 14px 0px;

          span {
            font-size: 19px;
            font-weight: 600;
            color: #01243c;
            line-height: 23px;
            margin-bottom: 0px;
            font-family: "Exo 2";
            font-style: italic;
            text-transform: uppercase;

            img {
              width: 45px;
              height: auto;
            }
          }
        }
      }
    }

    .box {
      padding: 20px 24px;
      background-color: #01243c;
      border-radius: 20px;
      cursor: pointer;

      .title {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          color: #fff;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 0px;
          font-weight: 600;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;

          span {
            color: #faba06;
          }
        }

        svg {
          color: #fff;
          font-size: 20px;
        }
      }

      p {
        min-height: 80px;
        max-height: max-content;

        span {
          display: block;
          color: #fff;
          padding-bottom: 6px;
          font-size: 10px;
          font-weight: 500;
          font-family: "Exo 2";
          font-style: italic;
          line-height: 12px;
        }
      }

      .priceRate {
        margin-top: -26px;

        h4 {
          font-size: 24px;
          font-weight: 700;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          margin-bottom: 0px;
          color: #fff;
          text-align: right;
          line-height: 30px;

          span {
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            text-transform: lowercase;
          }
        }
      }
    }

    .box.add-animation {
      animation: 500ms fadeIn ease-in-out;
    }

    h6 {
      position: absolute;
      right: 60px;
      top: 25px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 18px;
      font-weight: 700;
      transform: translate(0px, 0px);
      color: #01243c;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      margin-bottom: 0px;
      cursor: pointer;
    }
  }
}

.giftCards {
  padding-bottom: 100px;
  background-color: #f8f8f8;
  padding-top: 35px;

  .headingGift {
    margin-bottom: 16px;

    h3 {
      font-size: 32px;
      font-weight: 700;
      color: #01243c;
      margin-bottom: 35px;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
    }

    h5 {
      font-size: 24px;
      line-height: 28px;
      color: #01243c;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 5px;
      text-align: center;
    }

    .btn {
      padding: 10px 100px;
      background-color: #01243c;
      border-radius: 50px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      border: 1px solid #01243c;
      margin: 24px auto 0px;
      color: #fff;
      cursor: pointer;
    }
  }

  .monthBox {
    .priceBox {
      position: relative;
      background-image: url(../../assets/LandingNew/priceBox.svg);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 52px 95px 56px;
      background-position: left;

      .content {
        width: 70%;

        h2 {
          font-weight: 700;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          font-size: 30px;
          line-height: 33px;
          color: #01243c;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 15px;

          img {
            width: 27px;
          }
        }

        h3 {
          font-weight: 600;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          font-size: 26px;
          line-height: 27px;
          color: #01243c;
          margin-bottom: 32px;

          span {
            color: #faba06;
          }
        }

        p {
          font-weight: 500;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: capitalize;
          font-size: 18px;
          line-height: 22px;
          color: #01243c;
          margin-bottom: 0px;
        }
      }

      .price {
        position: absolute;
        bottom: 0px;
        right: 70px;
        transform: translate(0px, -50px);
        font-weight: 700;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        font-size: 45px;
        line-height: 54px;
        color: #01243c;
        margin-bottom: 0px;
      }
    }

    h6 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
      line-height: 22px;
      font-family: "Exo 2";
      font-style: italic;
      text-align: center;
      margin-top: -14px;
      color: #01243c;
    }

    .btn {
      display: none;
    }
  }
}

.wavePools {
  background-image: url(../../assets/LandingNew/wavePoolsBackground.png);
  padding: 40px 0px 250px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: bottom;

  .wavePoolsContent {
    text-align: center;
    color: #fff;

    h2 {
      font-weight: 700;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      font-size: 40px;
      margin-bottom: 30px;
    }

    h4 {
      font-weight: 500;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      font-size: 35px;
      margin-bottom: 0px;
    }
  }
}

.startWatching {
  background-image: url(../../assets/LandingNew/startWatching.svg);
  padding: 36px 0px;
  background-repeat: no-repeat;
  background-size: cover;

  .wavePoolsContent {
    text-align: center;
    color: #fff;

    h2 {
      font-weight: 700;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      font-size: 32px;
      margin-bottom: 30px;
    }

    h4 {
      font-weight: 300;
      font-family: "Exo 2";
      font-style: italic;
      font-size: 24px;
      margin-bottom: 10px;
      line-height: 28px;
    }

    .btn {
      padding: 10px 100px;
      background-color: #fff;
      border-radius: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      border: 1px solid #fff;
      margin: 50px auto 0px;
      color: #01243c;
      cursor: pointer;
    }
  }
}

.poolsBox {
  padding-bottom: 50px;
  margin-top: -110px;
  background-color: #f8f8f8;

  .boxPools {
    padding: 18px;
    background-color: #eaeaea;
    border-radius: 31px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
    }

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
    }
  }
}

.cameraSection {
  background-color: #f8f8f8;
  padding: 0px 0px 80px;

  .backgroundColor {
    padding: 36px 0px 0px;
    border-top: 2.96px solid #01243c24;

    .content {
      text-align: left;

      h3 {
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 18px;
        color: #01243c;
      }

      h4 {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        font-family: "Exo 2";
        font-style: italic;
        margin-bottom: 35px;
        color: #01243c;
      }
    }

    .contentBox {
      text-align: center;
      margin-bottom: 50px;

      .imgHeight {
        height: 65px;
        margin-bottom: 10px;

        img {
          width: auto;
          height: auto;
        }
      }

      h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 0px;
        color: #01243c;
      }
    }

    .boxContact {
      background-color: #fff;
      box-shadow: 0px 7.481865406036377px 20.762176513671875px 0px #0000000d;
      border-radius: 70px;
      padding: 35px 60px;
      text-align: center;

      h3 {
        font-weight: 700;
        font-size: 30px;
        line-height: 54px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #01243c;
      }

      .btn {
        text-decoration: none;
        color: #ffffff;
        border: 2.5px solid #01243c;
        background-color: #01243c;
        border-radius: 80px;
        padding: 16px 40px;
        font-size: 18px;
        height: 58px;
        font-weight: 700;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 192px;
      }
    }
  }
}

.whyVideo {
  background-image: url(../../assets/LandingNew/FooterBackGround.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0px 40px;
  background-position: top;
  border-radius: 24px 24px 0px 0px;

  .content {
    margin-bottom: 35px;
    text-align: center;

    h3 {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 36px;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      color: #fff;
    }
  }

  .contentBox {
    text-align: center;
    margin-bottom: 20px;

    .imgHeight {
      margin-bottom: 10px;

      img {
        width: auto;
        height: 82px;
      }
    }

    h5 {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      margin-bottom: 0px;
      color: #fff;
    }
  }

  .contentBottom {
    text-align: center;
    margin-top: 40px;

    h4 {
      margin-bottom: 0px;
      font-weight: 700;
      font-size: 30px;
      line-height: 60px;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      color: #fff;
    }

    .btn {
      background-color: #fff;
      color: #01243c;
      border-radius: 50px;
      height: 55px;
      width: 215px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px auto 0px;
      border: 1px solid #fff;
      font-size: 20px;
      font-weight: 700;
      font-family: "Exo 2";
      font-style: italic;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.dhidden {
  display: none;
}

/*****************************************
  RESPONSIVE MEDIA QUERY
  *****************************************/
@media (max-width: 400px) {
  .locationSection {
    .mapBox {
      .owl-stage-outer {
        position: relative;

        &:before {
          right: 7px !important;
          background: linear-gradient(
            270deg,
            #f8f8f8 72.55%,
            rgba(255, 255, 255, 0) 113.91%
          ) !important;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .videosAnywhare {
    padding-bottom: 0px;

    .titleHeading {
      margin-bottom: 9px;

      h3 {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .videoBox {
      .subHeading {
        display: block;
        margin-left: 0px;
        margin-bottom: 0px;

        h5 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .videoModal {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;

      .imgweb {
        position: relative;
        transform: none;
        margin-bottom: 0px;
        margin-top: 0px;
        transform: none !important;

        .laptopVideoMargeWeb {
          display: none;
        }

        .laptopVideoMargeMobile {
          display: block;

          video {
            width: 100%;
          }
        }

        .mobileimg {
          display: block !important;
        }

        .webimg {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      ul {
        padding-right: 0px !important;
        gap: 30px;

        li {
          gap: 10px;
          flex-wrap: wrap;
          padding: 14px 0px;
          justify-content: center;

          span {
            font-size: 12px;
            line-height: 14px;
            text-align: center;

            img {
              width: 42px;
              height: 42px;
            }
          }
        }
      }
    }
  }

  .locationSection {
    padding-top: 30px;
    padding-bottom: 0px;

    .locationContent {
      margin-bottom: 14px;

      h3 {
        font-size: 24px;
        line-height: normal;
      }

      .TapsButton {
        width: 110px;
        height: 36px;
        border-width: 2px;

        .btn {
          font-size: 10px;

          &.active {
            height: 36px;
          }
        }
      }
    }

    .mapLocation {
      position: relative;
      border-radius: 25px;
      padding: 0px 6px;

      .mapContent {
        padding: 8px 20px !important;
        margin: 0px 6px;

        h5 {
          font-size: 18px !important;
        }
      }
    }

    .mapBox {
      padding: 40px 0px;
      margin-top: -140px;

      .box {
        box-shadow: 0px 4px 34px 0px #00000040;
        height: 200px !important;
        width: 100%;
        border-radius: 26px;
        padding: 20px 10px;

        &.active {
          width: 100%;
        }

        &:hover {
          width: 100%;
        }

        h3 {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
          line-height: 14px;
        }

        span {
          font-size: 12px;
          line-height: 14px;
          padding: 8px 15px;
        }

        .bottomImage {
          width: 90px;
          bottom: -10px;
        }

        .boxCenter {
          .topImage {
            margin: 0px !important;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translate(-50%, -70%);
          }
        }
      }

      .owl-nav {
        display: none;
      }

      .owl-dots {
        margin-top: 20px;

        .owl-dot {
          margin-top: 20px;

          &.active {
            span {
              width: 80px;
              opacity: 1;
            }
          }

          span {
            width: 13px;
            height: 5px;
            background-color: #01243c;
            opacity: 0.46;
          }
        }
      }

      .owl-stage-outer {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 30%;
          height: 200px;
          top: 50%;
          right: 23px;
          transform: translate(100%, -50%);
          z-index: 1;
          border-radius: 26px;
          background: linear-gradient(
            270deg,
            #f8f8f8 32.55%,
            rgba(255, 255, 255, 0) 113.91%
          );
        }
      }

      .owl-stage-outer {
        .owl-stage {
          .active {
            &:first-child {
              .box {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .startWatching .wavePoolsContent h4 span {
    display: none;
  }

  .pricingSection {
    padding-bottom: 35px;
    padding-top: 35px;
    background-color: #f8f8f8;

    .content {
      margin-bottom: 10px;

      h2 {
        font-size: 24px;
      }
    }

    .paddingLeft {
      padding-left: 0px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 160px;
    }

    .boxPricingTop {
      padding: 22px 22px;
      border: none;
      display: block !important;
      width: 100%;
      margin-top: -150px;
      border-radius: 30px;

      &.one {
        border-width: 5px;
      }

      &.active {
        order: 1;
        z-index: 9;
      }

      .title {
        margin-bottom: 14px;

        h3 {
          font-size: 18px;
          line-height: 21px;
        }
      }

      p {
        min-height: 116px;

        span {
          font-size: 12px;
          line-height: 14px;
          padding-bottom: 9px;
        }
      }

      .priceRate {
        margin-top: -40px;

        h4 {
          font-size: 30px;

          span {
            font-size: 12px;
          }
        }
      }
    }

    .mobileSmallBox {
      box-shadow: 0px 12.6097202301px 44.1340179443px 0px
        rgba(0, 0, 0, 0.1019607843);
      background-color: #fff;
      border-radius: 25px;
      padding: 22px 30px 12px;
      position: relative;
    }

    .smallBox {
      box-shadow: none;
      background-color: transparent;
      border-radius: 0px;
      padding: 0;
      margin-top: 0px;
      position: static;

      .list {
        ul {
          padding-left: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
          gap: 40px;

          li {
            gap: 8px;

            span {
              font-size: 12px;
              line-height: 14px;

              img {
                width: 28px;
              }
            }
          }
        }
      }

      .box {
        display: none;
      }

      h6 {
        width: 100%;
        bottom: 0px;
        top: auto;
        display: block;
        text-align: center;
        right: 0px;
        transform: translate(0px, 36px);
        font-size: 12px;
        line-height: 14px;

        svg {
          display: none;
        }

        a {
          display: block !important;
          font-size: 12px;
          font-weight: 700;
          text-transform: capitalize;
          color: #01243c;
          font-family: "Exo 2";
          font-style: italic;
          text-decoration: underline;
        }
      }
    }
  }

  .giftCards .monthBox .priceBox {
    padding: 76px 95px 76px;
  }

  .faqModal {
    .faqStart {
      min-width: 90% !important;
      padding: 40px 40px 40px 40px !important;
      width: 90% !important;

      .faqContent {
        height: calc(45vh - 400px) !important;

        .MuiAccordion-rounded {
          padding: 0px 20px !important;

          .MuiAccordionSummary-gutters {
            min-height: 68px !important;
          }
        }

        .topContent {
          gap: 12px !important;
        }
      }

      .faqunderContent {
        padding: 0px 55px !important;
      }
    }
  }
}

@media (max-width: 599px) {
  .giftCards {
    padding-bottom: 46px;
    padding-top: 55px;

    .headingGift {
      .btn {
        display: none !important;
      }
    }

    .monthBox {
      .priceBox {
        padding: 24px 45px 32px;
        position: relative;
        left: -12px;
      }

      .btn {
        display: flex;
      }
    }
  }

  .faqModal {
    .faqStart {
      padding: 20px 20px 20px 20px !important;

      .faqContent {
        height: calc(45vh - 50px) !important;

        .MuiAccordion-rounded {
          padding: 0px 0px !important;

          .MuiAccordionSummary-gutters {
            min-height: 55px !important;
            padding: 0px 6px;
          }
        }

        .topContent {
          gap: 8px !important;

          h2 {
            font-size: 17px !important;
          }
        }
      }

      .faqunderContent {
        padding: 0px 0px 0px 42px !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .dhidden {
    display: block;
  }

  .howItWorks {
    padding: 30px 0px 70px;
  }

  .mhidden {
    display: none;
  }

  .howItWorks {
    padding: 40px 0px 0px;
    background-color: #f8f8f8;

    .hotItHeading {
      padding-top: 0px;

      .topTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        h2 {
          font-size: 24px;
          line-height: normal;
        }

        h5 {
          font-size: 11px;
          line-height: normal;
        }
      }

      .stepsfive {
        h3 {
          font-size: 16px;
          line-height: normal;
        }

        h4 {
          font-size: 16px;
          line-height: normal;
        }
      }
    }

    .tabsAnimation {
      .css-19kzrtu {
        padding: 10px 0px 0px;
      }

      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          display: block;
          white-space: normal;

          button {
            width: 100%;

            &:nth-child(1) {
              &:before {
                right: 30px;
                top: 125px;
                transform: rotate(90deg);
                width: 180px;
              }
            }

            &:nth-child(2) {
              &:before {
                right: 100px;
                top: 60px;
                rotate: 270deg;
              }
            }

            &:nth-child(3) {
              &:before {
                right: 30px;
                top: 125px;
                transform: rotate(90deg);
              }
            }

            &:nth-child(4) {
              &:before {
                right: 100px;
                top: 50px;
                rotate: 270deg;
              }
            }
          }
        }

        .MuiTabs-indicator {
          display: none;
        }
      }

      .tabsOne {
        .MuiGrid-grid-xs-5 {
          max-width: 100%;
          flex-basis: 100%;
        }

        .MuiGrid-grid-xs-8 {
          max-width: 100%;
          flex-basis: 100%;
        }

        .tobsBottom {
          .btn {
            font-size: 20px;
          }
        }
      }

      .tabsTwo {
        .MuiGrid-grid-xs-5 {
          max-width: 100%;
          flex-basis: 100%;
        }

        .MuiGrid-grid-xs-8 {
          max-width: 100%;
          flex-basis: 100%;
        }

        .tobsBottom {
          .btn {
            font-size: 20px;
          }
        }
      }

      .tabsThree {
        .MuiGrid-grid-xs-7 {
          max-width: 100%;
          flex-basis: 100%;
        }

        .MuiGrid-grid-xs-8 {
          max-width: 100%;
          flex-basis: 100%;
        }

        .tobsBottom {
          .btn {
            font-size: 20px;
          }
        }
      }

      .tabsBottomButton {
        text-align: center;
        margin-top: 12px;

        .btn {
          padding: 15px 50px;
          font-size: 16px;
          height: 40px;
        }

        h5 {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 0px;
        }

        a {
          font-size: 10px;
          line-height: 12px;
          font-weight: bold;
        }
      }
    }
  }

  .joinBox {
    padding: 50px 0px 0px 0px;

    .boxDesign {
      padding: 14px 18px 20px;
      border-radius: 20px;
      display: flex;

      span {
        font-size: 14px;
        line-height: normal;
      }

      h3 {
        font-size: 11px;
        line-height: normal;
        margin-bottom: 0px;
      }

      .userList {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          font-size: 11px;
          line-height: normal;
        }

        .userImg1 {
          display: block;
          margin: 5px 0px 5px 0px;
          height: 60px;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;

            &:nth-child(2) {
              transform: translate(-20px, 0px);
            }

            &:nth-child(3) {
              transform: translate(-40px, 0px);
            }
          }
        }

        .imgSize {
          img {
            width: auto !important;
            height: 40px !important;
          }
        }

        .userImg {
          display: none;
          align-items: center;

          img {
            width: 30px;
            height: 30px;

            &:nth-child(2) {
              transform: translate(-20px, 0px);
            }

            &:nth-child(3) {
              transform: translate(-40px, 0px);
            }
          }
        }
      }

      .userPlus {
        display: flex;
        gap: 3px;
      }
    }
  }

  .videosAnywhare {
    padding-bottom: 0px;
    padding-top: 70px;

    .titleHeading {
      margin-bottom: 9px;

      h3 {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .videoBox {
      .subHeading {
        display: block;
        margin-left: 0px;
        margin-bottom: 0px;

        h5 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .videoModal {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;

      .imgweb {
        position: relative;
        transform: none;
        margin-bottom: 0px;
        margin-top: 0px;

        .mobileimg {
          display: block;
        }

        .laptopVideoMargeMobile {
          display: block;

          video {
            width: 100%;
          }
        }

        .webimg {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      ul {
        padding-right: 0px;
        gap: 30px;
        margin-top: 0px;

        li {
          gap: 10px;
          flex-wrap: wrap;
          padding: 14px 0px;
          justify-content: center;

          span {
            font-size: 12px;
            line-height: 14px;
            text-align: center;

            img {
              width: 42px;
              height: 42px;
            }
          }
        }
      }
    }
  }

  .locationSection {
    padding-top: 60px;
    padding-bottom: 0px;

    .locationContent {
      margin-bottom: 14px;

      h3 {
        font-size: 24px;
        line-height: normal;
      }

      .TapsButton {
        width: 110px;
        height: 36px;
        border-width: 2px;

        .btn {
          font-size: 10px;

          &.active {
            height: 36px;
          }
        }
      }
    }

    .mapLocation {
      position: relative;
      border-radius: 25px;
      padding: 0px 6px;

      .mapContent {
        padding: 8px 20px !important;
        margin: 0px 6px;

        h5 {
          font-size: 18px !important;
        }
      }
    }

    .mapBox {
      padding: 40px 0px;
      margin-top: -140px;

      .box {
        box-shadow: 0px 4px 34px 0px #00000040;
        height: 150px !important;
        width: 100%;
        border-radius: 26px;
        padding: 20px 10px;

        &.active {
          width: 100%;
        }

        &:hover {
          width: 100%;
          transition: none;
          transform: none;
        }

        h3 {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
          line-height: 14px;
        }

        span {
          font-size: 12px;
          line-height: 14px;
          padding: 8px 15px;
        }

        .bottomImage {
          width: 90px;
        }

        .boxCenter {
          .topImage {
            margin: 0px !important;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translate(-50%, -70%);
          }
        }
      }

      .owl-nav {
        display: none;
      }

      .owl-dots {
        margin-top: 20px;

        .owl-dot {
          margin-top: 20px;

          &.active {
            span {
              width: 80px;
              opacity: 1;
            }
          }

          span {
            width: 13px;
            height: 5px;
            background-color: #01243c;
            opacity: 0.46;
          }
        }
      }

      .owl-stage-outer {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 30%;
          height: 150px;
          top: 50%;
          right: 18%;
          transform: translate(100%, -50%);
          z-index: 1;
          border-radius: 26px;
          background: linear-gradient(
            270deg,
            #f8f8f8 32.55%,
            rgba(255, 255, 255, 0) 113.91%
          );
        }
      }

      .owl-stage-outer {
        .owl-stage {
          .active {
            &:first-child {
              .box {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .whyVideo {
    padding: 10px 0px 16px;

    .content {
      h3 {
        font-size: 20px;
      }
    }

    .contentBox {
      margin-bottom: 12px;

      .imgHeight {
        img {
          width: auto;
          height: 30px;
        }
      }

      h5 {
        font-size: 7px;
        line-height: normal;

        br {
          display: none;
        }
      }
    }

    .contentBottom {
      margin-top: 10px;

      h4 {
        font-size: 16px;
        line-height: 20px;
      }

      .btn {
        height: 44px;
        width: 200px;
        font-size: 16px;
      }
    }
  }

  .cameraSection {
    padding: 0px 0px 35px;

    .backgroundColor {
      padding: 20px 0px 0px;
      border-radius: 2px;
      border-top: 1.96px solid rgba(1, 36, 60, 0.1411764706);

      .content {
        h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 15px;
        }

        h4 {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 26px;
        }
      }

      .contentBox {
        margin-bottom: 28px;

        .imgHeight {
          height: 40px;
          margin-bottom: 8px;

          img {
            height: 100%;
          }
        }

        h5 {
          font-size: 10px;
          line-height: 14px;

          br {
            display: none;
          }
        }
      }

      .boxContact {
        border-radius: 22px;
        padding: 18px 14px;
        margin-top: 26px;

        h3 {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 18px;
        }

        .btn {
          padding: 9px 24px;
          font-size: 11px;
          height: 32px;
          width: 118px;
        }
      }
    }
  }

  .wavePools {
    padding: 20px 0px 100px;

    .wavePoolsContent {
      h2 {
        font-size: 24px;
        margin-bottom: 8px;
      }

      h4 {
        font-size: 18px;
      }
    }
  }

  .poolsBox {
    padding-bottom: 36px;
    margin-top: -50px;

    .boxPools {
      padding: 12px;
      border-radius: 18px;

      h3 {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .giftCards {
    padding-bottom: 46px;
    padding-top: 55px;

    .headingGift {
      h3 {
        font-size: 24px;
        margin-bottom: 6px;
      }

      h5 {
        font-size: 18px;
        line-height: 22px;
        text-align: left;
      }

      .btn {
        padding: 8px 50px;
        background-color: #01243c;
        border-radius: 50px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        border: 1px solid #01243c;
        margin: 30px auto 0px;
        color: #fff;
      }
    }

    .monthBox {
      .priceBox {
        padding: 24px 45px 32px;
        position: relative;
        left: -12px;

        .content {
          width: 90%;

          h2 {
            font-size: 20px;
            line-height: 22px;
            gap: 10px;

            img {
              width: 18px;
            }
          }

          h3 {
            font-size: 17px;
            line-height: 19px;
            margin-bottom: 20px;
          }

          p {
            font-size: 12px;
            line-height: 14px;
          }
        }

        .price {
          right: 30px;
          transform: translate(0px, -20px);
          font-size: 30px;
          line-height: 36px;
        }
      }

      h6 {
        font-size: 12px;
        line-height: 13px;
        margin-top: 4px;
      }

      .btn {
        padding: 8px 50px;
        background-color: #01243c;
        border-radius: 50px;
        height: 40px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        border: 1px solid #01243c;
        margin: 10px auto 0px;
        color: #fff;
      }
    }
  }

  .pricingSection {
    padding-bottom: 35px;
    padding-top: 70px;
    background-color: #f8f8f8;

    .content {
      margin-bottom: 10px;

      h2 {
        font-size: 24px;
      }
    }

    .paddingLeft {
      padding-left: 0px;
      // min-height: 350px;
      // max-height: 350px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 160px;
    }

    .boxPricingTop {
      padding: 12px 22px 22px;
      border: none;
      display: block !important;
      width: 100%;
      margin-top: -140px;
      border-radius: 30px;
      border-width: 5px !important;

      &.one {
        border-width: 5px;
      }

      &.active {
        order: 1;
        z-index: 9;
      }

      .title {
        margin-bottom: 14px;

        h3 {
          font-size: 18px;
          line-height: 21px;
        }
      }

      p {
        min-height: 116px;

        span {
          font-size: 12px;
          line-height: 14px;
          padding-bottom: 9px;
        }
      }

      .priceRate {
        margin-top: -40px;

        h4 {
          font-size: 30px;

          span {
            font-size: 12px;
          }
        }
      }
    }

    .mobileSmallBox {
      box-shadow: 0px 12.6097202301px 44.1340179443px 0px
        rgba(0, 0, 0, 0.1019607843);
      background-color: #fff;
      border-radius: 25px;
      padding: 10px 30px 12px;
      position: relative;
    }

    .smallBox {
      box-shadow: none;
      background-color: transparent;
      border-radius: 0px;
      padding: 0;
      margin-top: 0px;
      position: static;

      .list {
        ul {
          padding-left: 0px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
          gap: 10px;

          li {
            gap: 8px;
            width: 40%;
            height: 56px;

            span {
              font-size: 12px;
              line-height: 14px;

              img {
                width: auto;
                height: 28px;
              }
            }
          }
        }
      }

      .box {
        display: none;
      }

      h6 {
        width: 100%;
        bottom: 0px;
        top: auto;
        display: block;
        text-align: center;
        right: 0px;
        transform: translate(0px, 36px);
        font-size: 12px;
        line-height: 14px;

        svg {
          display: none;
        }

        a {
          display: block !important;
          font-size: 12px;
          font-weight: 700;
          text-transform: capitalize;
          color: #01243c;
          font-family: "Exo 2";
          font-style: italic;
          text-decoration: underline;
        }
      }
    }
  }

  .pricingSection .boxPricingTop .title svg {
    font-size: 25px;
  }

  .startWatching {
    padding: 20px 0px;

    .wavePoolsContent {
      h2 {
        font-size: 20px;
        margin-bottom: 9px;
      }

      h4 {
        font-size: 14px;
        margin-bottom: 0px;
        line-height: 20px;

        span {
          display: none;
        }
      }

      .btn {
        padding: 10px 40px;
        height: 39px;
        font-size: 15px;
        line-height: 20px;
        margin: 30px auto 0px;
      }
    }
  }

  .tabsAnimation {
    .mobildeTabs {
      text-align: center;
      margin: 0px auto;
      max-width: 85%;

      ul {
        margin-bottom: 0px;
        list-style: none;
        padding-left: 0px;

        li {
          margin-bottom: 15px;
          height: 150px;
          position: relative;

          &.active {
            // height: 100%;
            height: 570px;

            .tabsButton {
              b {
                display: none;
              }

              img {
                display: block;
                transition: 0.5s;
              }
            }

            .tabScrollShow {
              display: block;
              transition: 0.5s;
              position: relative;
              z-index: 99;

              video {
                height: auto;
                width: 100%;
              }
            }

            &.lastTabs {
              height: auto;
            }
          }

          .tabsButton {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            border: 3px solid #01243c;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            background: #f8f8f8;
            position: relative;
            margin: 0px auto 12px;

            b {
              font-size: 50px;
              font-weight: 700;
              line-height: 60px;
              text-transform: uppercase;
              color: #01243c;
              font-family: "Exo 2";
              font-style: italic;
            }

            img {
              height: auto;
              width: 46px;
              display: none;
            }
          }

          .title {
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            text-transform: uppercase;
            color: #01243c;
            font-family: "Exo 2";
            font-style: italic;
          }

          .tabScrollShow {
            margin: 50px 0px;
            display: none;

            h3 {
              font-size: 16px;
              line-height: 20px;
              font-weight: 700;
              margin-bottom: 12px;
              text-transform: uppercase;
              color: #86a9c5;
              font-family: "Exo 2";
              font-style: italic;
            }

            img {
              height: auto;
              width: 100%;
              max-width: 390px;
            }

            h4 {
              font-size: 12px;
              line-height: 14px;
              font-weight: 600;
              margin-bottom: 0px;
              color: #01243c;
              font-family: "Exo 2";
              font-style: italic;
            }

            .btn {
              text-decoration: none;
              background-color: #01243c;
              border: 1px solid #01243c;
              border-radius: 50px;
              color: #fff;
              padding: 15px 30px;
              font-weight: 700;
              font-size: 14px;
              text-transform: uppercase;
              font-family: "Exo 2";
              font-style: italic;
              height: 37px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 22px auto 0px;
            }
          }

          &.right {
            &:before {
              content: "";
              position: absolute;
              width: 180px;
              height: 180px;
              border: 3px solid #86a9c5;
              border-radius: 50%;
              top: 28px;
              transform: translate(-32%, 0%);
              left: 50%;
              border-left-color: transparent;
            }
          }

          &.left {
            &:before {
              content: "";
              position: absolute;
              width: 180px;
              height: 180px;
              border: 3px solid #86a9c5;
              border-radius: 50%;
              top: 28px;
              transform: translate(-66%, 0%);
              left: 50%;
              border-right-color: transparent;
            }
          }
        }

        li {
          &.active {
            &.right {
              &:before {
                width: 570px;
                height: 570px;
                border-top-color: transparent;
                rotate: -45deg;
                top: -80px;
                left: 20%;
              }
            }

            &.left {
              &:before {
                width: 570px;
                height: 570px;
                border-top-color: transparent;
                rotate: 45deg;
                top: -20px;
                left: 55%;
                transform: translate(-30%, 50%);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .tabsAnimation .mobildeTabs ul li .tabScrollShow img {
    height: auto;
    width: 100%;
    max-width: unset;
  }
}

@media (max-width: 576px) {
  .tabsAnimation {
    .mobildeTabs {
      ul {
        li {
          &.active {
            &.right {
              &:before {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .joinBox .boxDesign .userList .userImg img {
    width: 46px;
    height: 46px;
  }

  .joinBox {
    .boxDesign {
      padding: 30px 30px;

      .userList {
        .userImg {
          img {
            height: 46px !important;
          }
        }
      }
    }
  }

  .videosAnywhare .videoModal {
    padding: 20px 20px;
    border-radius: 26px;

    .imgweb {
      transform: translate(11%, -44%);

      .webimg {
        width: 250px;
      }

      .laptopVideoMargeWeb {
        video {
          width: 184px;
          top: 7px;
          height: 116px;
        }
      }
    }

    ul {
      padding-right: 200px;

      li {
        gap: 12px;

        span {
          font-size: 12px;

          img {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  .videosAnywhare .videoModal ul li span img {
    width: 40px;
    height: 40px;
  }

  .videosAnywhare .videoBox .subHeading {
    gap: 40px;
    margin-left: 20px;
  }

  .locationSection .mapBox .box {
    width: auto;
    height: 210px;
  }

  .pricingSection .smallBox h6 {
    right: 30px;
    font-size: 16px;
  }

  .pricingSection .paddingLeft {
    padding-left: 20px;
  }

  .pricingSection .smallBox .list ul {
    padding-left: 0px !important;
  }

  .giftCards .headingGift h3 {
    font-size: 24px;
  }

  .giftCards .headingGift h5 {
    font-size: 20px;
    line-height: 24px;
  }

  .giftCards .headingGift .btn {
    padding: 10px 30px;
    width: 100%;
  }

  .giftCards .monthBox .priceBox {
    padding: 18px 42px 34px;
    background-size: 100%;

    .content {
      h2 {
        margin-bottom: 5px;
      }

      h3 {
        margin-bottom: 10px;
      }
    }

    .price {
      right: 37px;
    }
  }

  .giftCards .monthBox .priceBox .content h2 {
    font-weight: 700;
    font-size: 18px;
  }

  .giftCards .monthBox .priceBox .content h2 img {
    width: 14px;
  }

  .giftCards .monthBox .priceBox .content h3 {
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .giftCards .monthBox .priceBox .content p {
    font-size: 12px;
    line-height: 18px;
  }

  .giftCards .monthBox .priceBox .price {
    bottom: 0px;
    right: 37px;
    font-size: 30px;
    line-height: 20px;
  }

  .giftCards .monthBox h6 {
    font-size: 14px;
  }

  .tabsNewDesign .tabsTopDesign:nth-child(2):before,
  .tabsNewDesign .tabsTopDesign:nth-child(4):before {
    top: 8px;
    width: 142px;
  }

  .tabsNewDesign .tabsTopDesign:nth-child(2):before,
  .tabsNewDesign .tabsTopDesign:nth-child(4):before {
    width: 142px !important;
  }

  .tabsNewDesign .tabsTopDesign:nth-child(2):before,
  .tabsNewDesign .tabsTopDesign:nth-child(4):before {
    width: 142px !important;
  }

  .tabsNewDesign .tabsTopDesign:nth-child(1):before,
  .tabsNewDesign .tabsTopDesign:nth-child(3):before {
    width: 142px !important;
  }

  .howItWorks .hotItHeading .stepsfive {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .joinBox .boxDesign .userList .userImg img {
    width: 50px;
    height: 50px;
  }

  .howItWorks .hotItHeading .stepsfive {
    margin-bottom: 30px;
  }

  .css-1vdm3s-MuiGrid-root {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  .giftCards .headingGift h3 {
    font-size: 24px;
  }

  .giftCards .headingGift h5 {
    font-size: 20px;
    line-height: 24px;
  }

  .giftCards .headingGift .btn {
    padding: 10px 30px;
    width: 100%;
  }

  .giftCards .monthBox .priceBox {
    padding: 35px 63px 46px;
    background-size: contain;
  }

  .giftCards .monthBox .priceBox .content h2 {
    font-weight: 700;
    font-size: 20px;
  }

  .giftCards .monthBox .priceBox .content h2 img {
    width: 14px;
  }

  .giftCards .monthBox .priceBox .content h3 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .giftCards .monthBox .priceBox .content p {
    font-size: 14px;
    line-height: 18px;
  }

  .giftCards .monthBox .priceBox .price {
    position: absolute;
    bottom: -8px;
    right: 80px;
    font-size: 25px;
    line-height: 18px;
  }

  .giftCards .monthBox h6 {
    font-size: 14px;
    line-height: 18px;
  }

  .tabsNewDesign .tabsTopDesign:nth-child(2):before,
  .tabsNewDesign .tabsTopDesign:nth-child(4):before {
    top: 14px;
    width: 152px;
  }

  .howItWorks .tabsAnimation .tabsOne .content .btn {
    left: 50%;
    transform: translate(-100px);
  }

  .tabsNewDesign .tabsTopDesign:nth-child(2):before,
  .tabsNewDesign .tabsTopDesign:nth-child(4):before {
    width: 130px !important;
  }

  .tabsNewDesign .tabsTopDesign:nth-child(1):before,
  .tabsNewDesign .tabsTopDesign:nth-child(3):before {
    width: 130px !important;
  }

  .joinBox {
    padding-top: 50px;
  }

  .joinBox .boxDesign .userList .imgSize img {
    height: 50px !important;
  }

  .howItWorks .tabsAnimation .tabsBottomButton {
    margin-top: 24px;
  }
}

@media (max-width: 768px) {
  .tabsNewDesign .tabsTopDesign:nth-child(2):before,
  .tabsNewDesign .tabsTopDesign:nth-child(4):before {
    width: 142px !important;
  }

  .tabsNewDesign .tabsTopDesign:nth-child(1):before,
  .tabsNewDesign .tabsTopDesign:nth-child(3):before {
    width: 142px !important;
  }

  .howItWorks .tabsAnimation .tabsOne .tobsBottom {
    margin-top: 0px;
  }
}

@media (min-width: 767px) and (max-width: 900px) {
  .giftCards .monthBox .priceBox {
    padding: 35px 39px 46px;
  }

  .giftCards .monthBox .priceBox .content h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .giftCards .monthBox .priceBox .content h2 img {
    width: 10px;
  }

  .giftCards .monthBox .priceBox .content h3 {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .giftCards .monthBox .priceBox .content p {
    font-size: 12px;
    line-height: 18px;
  }

  .giftCards .monthBox .priceBox .price {
    right: 40px;
    font-size: 20px;
  }
}

@media (min-width: 600px) and (max-width: 720px) {
  .giftCards .monthBox .priceBox {
    padding: 35px 38px 43px;
    background-size: contain;
  }

  .giftCards .monthBox .priceBox .content h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .giftCards .monthBox .priceBox .content h3 {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 12px;
  }

  .giftCards .monthBox .priceBox .price {
    right: 33px;
    transform: translate(0px, -31px);
    font-size: 22px;
    line-height: 36px;
  }

  .giftCards .monthBox .priceBox .content h2 img {
    width: 12px;
  }

  .giftCards .monthBox h6 {
    margin-top: -16px;
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  .giftCards .monthBox .priceBox {
    padding: 46px 64px 51px;
    left: 0;
    background-position: top;
  }

  .giftCards .monthBox .priceBox .content h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .giftCards .monthBox .priceBox .content h3 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .giftCards .monthBox .priceBox .content p {
    font-size: 17px;
    line-height: 24px;
  }

  .giftCards .monthBox .priceBox .price {
    right: 50px;
    transform: translate(0px, -34px);
    font-size: 26px;
    line-height: 42px;
  }
}

@media (max-width: 1100px) {
  .pricingSection .smallBox .list ul {
    padding-left: 70px;
  }
}

@media (max-width: 1199px) {
  .faqModal {
    .faqStart {
      min-width: 90% !important;
      padding: 20px !important;
      width: 90% !important;

      .faqContent {
        height: calc(45vh - 50px) !important;

        .MuiAccordion-rounded {
          padding: 0px 20px !important;

          .MuiAccordionSummary-gutters {
            min-height: 68px !important;
          }
        }

        .topContent {
          gap: 12px !important;
        }
      }

      .faqunderContent {
        padding: 0px 55px !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 990px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
    margin: 0 auto;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 730px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
    margin: 0 auto;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
    margin: 0 auto;
  }
}

@media (max-width: 412px) {
  .locationSection .mapBox .owl-stage-outer:before {
    content: "";
    position: absolute;
    display: block;
    width: 30%;
    height: 150px;
    top: 50%;
    right: 10px;
    transform: translate(100%, -50%);
    z-index: 1;
    border-radius: 26px;
    background: linear-gradient(
      270deg,
      #f8f8f8 42.55%,
      rgba(255, 255, 255, 0) 113.91%
    );
  }
}

@media screen and (device-width: 1024px) {
  .joinBox {
    .boxDesign {
      padding: 20px 30px;

      .userList {
        .userImg {
          img {
            width: 55px;
            height: 55px;
          }
        }
      }
    }
  }

  .giftCards .monthBox .priceBox .price {
    right: 40px;
    transform: translate(0px, -20px);
    font-size: 30px;
  }

  .pricingSection .smallBox h6 {
    right: 50px;
    top: 25px;
    font-size: 12px;
  }

  .videosAnywhare {
    .videoBox {
      .subHeading {
        margin: 0 auto;
        display: block;
      }
    }

    .videoModal {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;

      .imgweb {
        position: relative;
        transform: none;
        margin-bottom: 0px;
        margin-top: -14px;

        .webimg {
          display: none;
        }

        .mobileimg {
          display: block;
          margin: 0 auto;
        }
      }

      ul {
        padding-right: 0px;
      }
    }
  }

  .locationSection .mapBox .box {
    width: 100%;
    height: 200px;
  }

  .pricingSection .boxPricingTop .priceRate {
    margin-top: -8px;

    h4 {
      font-size: 26px;
    }
  }

  .pricingSection .smallBox .list ul {
    padding-left: 40px;

    li {
      gap: 12px;

      span {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .giftCards .monthBox {
    h6 {
      font-size: 14px !important;
    }

    .priceBox {
      background-size: 100%;
      padding: 26px 48px 45px;

      h2 {
        font-size: 20px !important;
        line-height: 24px !important;

        img {
          width: 16px !important;
        }
      }

      h3 {
        font-size: 16px !important;
        line-height: 20px !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }
  }

  .pricingSection .smallBox .box {
    height: 100%;

    .priceRate {
      margin-top: -16px;
    }
  }

  .cameraSection .backgroundColor .boxContact {
    padding: 15px 60px;

    h3 {
      font-size: 24px;
    }

    .btn {
      font-size: 16px;
      height: 48px;
    }
  }
}

@media screen and (device-width: 768px) {
  .joinBox {
    .boxDesign {
      padding: 20px 30px;

      .userList {
        .userImg {
          img {
            width: 55px;
            height: 55px;
          }
        }
      }
    }
  }

  .giftCards .monthBox .priceBox .price {
    right: 40px;
    transform: translate(0px, -40px);
    font-size: 30px;
  }

  .videosAnywhare {
    .videoBox {
      .subHeading {
        margin: 0 auto;
        display: block;
      }
    }

    .videoModal {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;

      .imgweb {
        position: relative;
        transform: none;
        margin-bottom: 0px;
        margin-top: -4px;

        .webimg {
          display: none;
        }

        .mobileimg {
          display: block;
          margin: 0 auto;
        }
      }

      ul {
        padding-right: 0px;
      }
    }
  }

  .locationSection .mapBox .box {
    width: 100%;
    height: 200px;
  }

  .pricingSection .boxPricingTop .priceRate {
    margin-top: -8px;

    h4 {
      font-size: 26px;
    }
  }

  .pricingSection .smallBox .list ul {
    padding-left: 40px;

    li {
      gap: 12px;

      span {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .giftCards .monthBox {
    h6 {
      font-size: 14px !important;
    }

    .priceBox {
      background-size: 100%;
      padding: 26px 48px 45px;

      h2 {
        font-size: 20px !important;
        line-height: 24px !important;

        img {
          width: 16px !important;
        }
      }

      h3 {
        font-size: 16px !important;
        line-height: 20px !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }
  }

  .pricingSection .smallBox .box {
    height: 100%;

    .priceRate {
      margin-top: -16px;
    }
  }

  .cameraSection .backgroundColor .boxContact {
    padding: 15px 60px;

    h3 {
      font-size: 24px;
    }

    .btn {
      font-size: 16px;
      height: 48px;
    }
  }
}

@media screen and (device-width: 820px) {
  .joinBox {
    .boxDesign {
      padding: 20px 30px;

      .userList {
        .userImg {
          img {
            width: auto !important;
            height: 55px !important;
          }
        }
      }
    }
  }

  .giftCards .monthBox .priceBox .price {
    right: 40px;
    transform: translate(0px, -50px);
    font-size: 30px;
  }

  .videosAnywhare {
    .videoBox {
      .subHeading {
        margin: 0 auto;
        display: block;
      }
    }

    .videoModal {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;

      .imgweb {
        position: relative;
        transform: none;
        margin-bottom: 0px;
        margin-top: -14px;

        .webimg {
          display: none;
        }

        .mobileimg {
          display: block;
          margin: 0 auto;
        }
      }

      ul {
        padding-right: 0px;
      }
    }
  }

  .locationSection .mapBox .box {
    width: 100%;
    height: 200px;
  }

  .pricingSection .boxPricingTop .priceRate {
    margin-top: -8px;

    h4 {
      font-size: 26px;
    }
  }

  .pricingSection .smallBox .list ul {
    padding-left: 40px;

    li {
      gap: 12px;

      span {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .giftCards .monthBox {
    h6 {
      font-size: 14px !important;
    }

    .priceBox {
      background-size: 100%;
      padding: 26px 48px 45px;

      h2 {
        font-size: 20px !important;
        line-height: 24px !important;

        img {
          width: 16px !important;
        }
      }

      h3 {
        font-size: 16px !important;
        line-height: 20px !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }
  }

  .pricingSection .smallBox .box {
    height: 100%;

    .priceRate {
      margin-top: -16px;
    }
  }

  .cameraSection .backgroundColor .boxContact {
    padding: 15px 60px;

    h3 {
      font-size: 24px;
    }

    .btn {
      font-size: 16px;
      height: 48px;
    }
  }
}

@media screen and (device-width: 912px) {
  .joinBox {
    .boxDesign {
      padding: 20px 30px;

      .userList {
        .userImg {
          img {
            width: 55px;
            height: 55px;
          }
        }
      }
    }
  }

  .pricingSection .smallBox .list ul {
    margin-top: 12px;
  }

  .mobileSmallBox {
    .MuiGrid-root {
      &.MuiGrid-item {
        min-width: 100%;
      }
    }
  }

  .giftCards .monthBox .priceBox .price {
    right: 40px;
    transform: translate(0px, -20px);
    font-size: 30px;
  }

  .videosAnywhare {
    .videoBox {
      .subHeading {
        margin: 0 auto;
        display: block;
      }
    }

    .videoModal {
      background-color: transparent;
      box-shadow: none;
      padding: 0px;

      .imgweb {
        position: relative;
        transform: none;
        margin-bottom: 0px;
        margin-top: -14px;

        .webimg {
          display: none;
        }

        .mobileimg {
          display: block;
          margin: 0 auto;
        }
      }

      ul {
        padding-right: 0px;
      }
    }
  }

  .locationSection .mapBox .box {
    width: 100%;
    height: 200px;
  }

  .pricingSection .boxPricingTop .priceRate {
    margin-top: -8px;

    h4 {
      font-size: 26px;
    }
  }

  .pricingSection .smallBox .list ul {
    padding-left: 40px;

    li {
      gap: 12px;

      span {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .giftCards .monthBox {
    h6 {
      font-size: 14px !important;
    }

    .priceBox {
      background-size: 100%;
      padding: 26px 48px 45px;

      h2 {
        font-size: 20px !important;
        line-height: 24px !important;

        img {
          width: 16px !important;
        }
      }

      h3 {
        font-size: 16px !important;
        line-height: 20px !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }
  }

  .pricingSection .smallBox .box {
    height: 100%;

    .priceRate {
      margin-top: -16px;
    }
  }

  .cameraSection .backgroundColor .boxContact {
    padding: 15px 60px;

    h3 {
      font-size: 24px;
    }

    .btn {
      font-size: 16px;
      height: 48px;
    }
  }
}

@media screen and (device-width: 540px) {
  .giftCards .monthBox .priceBox {
    padding: 54px 65px 54px;
  }

  .tabsAnimation .mobildeTabs ul li.active.right:before {
    width: 560px;
    height: 560px;
    left: 24%;
  }

  .tabsAnimation .mobildeTabs ul li.active {
    height: 560px !important;
  }

  .tabsAnimation .mobildeTabs ul li.active.left:before {
    width: 560px;
    height: 550px;
  }
}

.panel pre.prettyprint {
  font-size: 13px;
  text-align: left;
  width: auto;
  font-weight: normal;
  margin: 0px;
  border: none;
}

.prettyprint .linenums {
  padding: 0;
  list-style: none;
}

.prettyprint ol li {
  background-color: black;
}

.panel.red .prettyprint .linenums > li:nth-child(n + 7):nth-child(-n + 9),
.panel.gray .prettyprint .linenums > li:nth-child(10),
.panel.purple .prettyprint .linenums > li:nth-child(4),
.panel.green .prettyprint .linenums > li:nth-child(n + 6):nth-child(-n + 10) {
  background-color: #222;
}

.tabscontainer .box {
  width: 100px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  background-color: white;
  border-radius: 8px;
  color: #222;
  font-weight: 700;
  margin-left: 20px;
  will-change: transform;
  display: none;
}

.box.active {
  background-color: orange;
  border: 2px solid white;
}

.description,
.final {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 80vh;
}

.tabscontainer {
  width: 500%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.panel {
  font-weight: 300;
  min-width: 100%;
  transform: none !important;
}

.tabsNewDesign {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
  text-align: center;

  .tabsTopDesign {
    position: relative;
    transition: opacity 0.5s ease-in-out;

    &.active {
      transition-delay: 0.2s;
      opacity: 1;

      .round {
        border: 4px solid #01243c;
        box-shadow: inset 0 4px 10px 0 rgba(1, 36, 60, 0.239);
        height: 100px;
        width: 100px;
        transform: scale(1.1);
        transition: transform 1.5s;

        img {
          display: block;
        }

        span {
          display: none;
        }
      }

      .tabsHeight {
        b {
          font-size: 18px;
          line-height: 20px;
          transition: transform 1.5s;
        }
      }
    }

    .round {
      align-items: center;
      background: #f8f8f8;
      border: 3px solid #01243c;
      border-radius: 50%;
      display: flex;
      height: 70px;
      justify-content: center;
      margin-bottom: 15px;
      padding: 15px;
      position: relative;
      width: 70px;
      transition: transform 1.5s;

      img {
        display: none;
        height: auto;
        width: 52px;
      }

      span {
        color: #01243c;
        font-family: "Exo 2";
        font-size: 30px;
        font-style: italic;
        font-weight: 700;
        line-height: 105px;
        text-transform: uppercase;
      }
    }

    .tabsHeight {
      min-height: 65px;

      b {
        color: #01243c;
        font-family: "Exo 2";
        font-size: 14px;
        font-style: italic;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      &:before {
        background-image: url(../../assets/LandingNew/WaveDesign.svg);
        position: absolute;
        content: "";
        height: 50px;
        width: 190px;
        background-repeat: no-repeat;
        right: 0px;
        background-size: cover;
        top: 10px;
        transform: translate(80%, -53%);
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      &:before {
        background-image: url(../../assets/LandingNew/WaveDesign.svg);
        position: absolute;
        content: "";
        height: 60px;
        width: 190px;
        background-repeat: no-repeat;
        right: 0px;
        background-size: cover;
        top: 3px;
        transform: translate(-83%, -50%);
        rotate: 180deg;
      }
    }
  }
}

@keyframes zoomFadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50%) scale(0.5);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@media screen and (device-width: 1166px) {
  .joinBox {
    .boxDesign {
      padding: 30px 30px;

      .userList {
        .userImg {
          img {
            height: 46px !important;
          }
        }
      }
    }
  }

  .videosAnywhare .videoModal {
    padding: 20px 20px;
    border-radius: 26px;

    .imgweb {
      transform: translate(11%, -44%);

      .webimg {
        width: 250px;
      }
    }

    ul {
      padding-right: 200px;

      li {
        gap: 12px;

        span {
          font-size: 12px;

          img {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  .giftCards .monthBox .priceBox {
    padding: 18px 42px 34px;
    background-size: 100%;

    .content {
      h2 {
        margin-bottom: 5px;
      }

      h3 {
        margin-bottom: 10px;
      }
    }

    .price {
      right: 37px;
    }
  }
}

@media screen and (device-width: 979px) {
  .giftCards .monthBox .priceBox {
    padding: 18px 42px 34px;
    background-size: 100%;

    .content {
      h2 {
        margin-bottom: 5px;
      }

      h3 {
        margin-bottom: 10px;
      }
    }

    .price {
      right: 37px;
    }
  }

  .joinBox {
    .boxDesign {
      padding: 20px 30px;

      .userList {
        .userImg {
          img {
            height: 46px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1050px) and (max-width: 1250px) {
  .joinBox {
    .boxDesign {
      padding: 30px 30px;

      .userList {
        .userImg {
          img {
            height: 46px !important;
          }
        }
      }
    }
  }

  .videosAnywhare .videoModal {
    padding: 20px 20px;
    border-radius: 26px;

    .imgweb {
      transform: translate(11%, -44%);

      .webimg {
        width: 250px;
      }

      .laptopVideoMargeWeb {
        video {
          width: 184px;
          top: 7px;
          height: 116px;
        }
      }
    }

    ul {
      padding-right: 200px;

      li {
        gap: 12px;

        span {
          font-size: 12px;

          img {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  .giftCards .monthBox .priceBox {
    padding: 65px 72px 65px;
    background-size: 100%;

    .content {
      h2 {
        margin-bottom: 5px;
        font-size: 24px;
      }

      h3 {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }

    .price {
      right: 37px;
    }
  }
}

.faqModal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .background {
    background: #00000094;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .faqStart {
    background-color: #f8f8f8;
    border-radius: 10px;
    height: auto;
    min-width: 60%;
    padding: 32px 60px 32px 140px;
    position: relative;
    width: 60%;
    z-index: 99999;

    .faqHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 14px;
      position: relative;

      h3 {
        font-size: 30px;
        font-weight: 700;
        line-height: 39px;
        text-transform: uppercase;
        color: #000;
        font-family: "Exo 2";
        font-style: italic;
        margin-bottom: 0px;
      }

      .closeModal {
        position: absolute;
        right: 0px;
        cursor: pointer;

        svg {
          width: 41px;
          height: 41px;
        }
      }
    }

    .faqContent {
      height: calc(80vh - 390px);
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;

      .MuiAccordion-rounded {
        background-color: transparent;
        border-radius: 0px;
        box-shadow: none;
        border-bottom: 1px solid #01243c24;
        padding: 0px 50px;

        .MuiAccordionSummary-gutters {
          min-height: 78px;
        }

        &::before {
          display: none;
        }

        .MuiAccordionSummary-content {
          margin: 0px;
        }

        .MuiAccordionSummary-expandIconWrapper {
          svg {
            font-size: 30px;
            color: #01243c;
          }
        }
      }

      .topContent {
        display: flex;
        align-items: center;
        gap: 30px;

        h2 {
          font-size: 20px;
          font-weight: 600;
          line-height: 18px;
          text-transform: uppercase;
          color: #01243c;
          font-family: "Exo 2";
          font-style: italic;
          margin-bottom: 7px;
        }

        img {
          width: 42px;
          height: 42px;
        }
      }
    }

    .faqunderContent {
      padding: 0px 52px;

      h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;
        margin-bottom: 30px;

        b {
          font-weight: 700;
        }
      }
    }

    .faqInformation {
      text-align: center;
      padding-top: 40px;

      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-transform: uppercase;
        color: #01243c;
        font-family: "Exo 2";
        font-style: italic;
        margin-bottom: 16px;
      }

      a {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: center;
        margin-bottom: 11px;

        span {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          text-transform: uppercase;
          color: #01243c;
          font-family: "Exo 2";
          font-style: italic;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
