.landingpagevideov::-webkit-media-controls {
  display: none !important;
}
.videoview {
  position: relative;
  background-color: #F8F8F8;
  overflow: hidden;

  &-container {
    height: 80vh;
    width: 100vw;
    border-radius: 0px 0px 40px 40px;
    overflow: hidden;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(1, 36, 60, 0.2) 0%, rgba(1, 36, 60, 0) 100%);
      z-index: 1;
    }

    
  @media screen and (max-width: 470px) {
    height: 70vh;
    border-radius: 0px 0px 24px 24px;
  }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;

      @media screen and (max-width: 470px) {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  &__title {
    // position: absolute;
    z-index: 1;
    // bottom: 25vh;
    width: 90vw;
    color: white;
    text-align: center;
    font-size: 44px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 28px;
    // left: 50%;
    // transform: translateX(-50%);
    @media screen and (min-width: 1600px) {
      font-size: 38px;
    }
    @media screen and (max-width: 700px) {
      font-size: 32px;
    }
    @media screen and (max-width: 500px) {
      font-size: 26px;
      top: auto;
      // bottom: 21vh;
    }
  }
}

.topBannerAlign{
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.videoview__controller {
  display: flex;

  @media screen and (max-width: 470px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 42px;
    min-width: 210px;
    max-width: 210px;
  }
  display: flex;
  background-color: white;
  border-radius: 100px;
  // position: absolute;
  // bottom: 60px;
  // left: 50%;
  min-width: 300px;
  max-width: 300px;
  margin: 0 auto;
  height: 59px;

  @media screen and (max-width: 1200px) {
    width: auto;
  }
  @media screen and (max-width: 1000px) {
    width: auto;
  }
  @media screen and (max-width: 730px) {
    width: auto;
    border-radius: 100px;
  }
  @media screen and (max-width: 470px) {
    width: auto;
  }
  // transform: translateX(-50%);

  &-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 1rem;
    font-size: 1.3rem;
    position: relative;

    svg {
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      position: relative;
      top: 2px;
      font-size: 3.5rem;
    }

    @media screen and (max-width: 1100px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 730px) {
      width: 1.1rem;
      padding: 1rem 1rem;
      text-align: center;
      font-size: 1rem;
    }

    font-weight: 700;
    font-style: italic;
    color: #01243C;
    @media screen and (max-width: 470px) {
      width: 100%;
      font-size: 1rem;
      text-align: center;
      display: flex;
      // flex-direction: column;
      padding: 0.3rem 1rem;
      align-items: center;
      justify-content: space-between;
      padding: 0.2rem;
    }
    div {
      margin-right: 0.9rem;
      @media screen and (max-width: 450px) {
        margin-right: 0.2rem;
      }
    }
    &:first-child {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      @media screen and (max-width: 470px) {
        width: 100%;
        border-right: unset;
      }
    }
    &:nth-child(3) {
      background-color: #01243C;
      border-radius: 8.5px;
      color: white;
      div {
        margin-right: 1.4rem;
      }
    }
  }
}

.watchkey {
  font-size: 24px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 1px;
  div {
    position: relative;
    left: 8px;
  }
  @media screen and (max-width: 470px) {
    width: 100%;
    font-size: 18px;
    position: relative;
    justify-content: center;
  }
}

.datetimepicker {
  position: absolute;
  top: 90px;
  background-color: white;
  padding: 1rem;
  width: 120%;
}

.controller-dropdown {
  position: absolute;
  top: 103%;
  background: white;
  width: 100%;
  text-align: center;
  left: 2px;
  border-radius: 5px;
}

.phone-controller {
  background-color: white;
  border-radius: 6px;
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
  }
  position: absolute;
  bottom: 15%;
  left: 50%;
  width: 87vw;
  transform: translateX(-50%);
  padding: 0;
  border-bottom: 0;
  &-item {
    text-align: center;
    border-left: 2px solid #01243C;
    border-right: 2px solid #01243C;
    &:first-child {
      border-top: 2px solid #01243C;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.7rem 0;
    font-weight: bold;
    font-style: italic;
    border-bottom: 1px solid #01243C;
    svg {
      position: absolute;
      right: 5px;
    }
  }

  .watch-item-controller {
    color: white;
    background-color: #01243C;
    border-radius: 2px;
  }
}
