.googleButtonHide{
  position: relative;
  width: 100%;

  .S9gUrf-YoZ4jf{
    // height: 100% !important;
    left: 0;
    opacity: 0.01;
    overflow: hidden;
    position: absolute !important;
    top: 50%;
    width: 100% !important;
    transform: translate(0%, -27%);
    z-index: 99;
  }
}

.authModal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  // background-color: rgba($color: white, $alpha: 0.45);
  background: #ffffffc7;
  z-index: 9999;

  .auth {
    background-color: #F8F8F8;
    width: 40%;
    max-width: 1300px;
    margin: auto;
    box-shadow: 0px 4.922305583953857px 17.228069305419922px 0px #00000040;
    border-radius: 65px;
    overflow: hidden;

    @media screen and (max-width: 1250px) {
      width: 40%;
      // height: 98vh;
    }
    @media screen and (max-width: 500px) {
      width: 90%;
      border-radius: 20px;
    }
    &__container {
      display: block;
      position: relative;
      // flex-direction: row-reverse;
    }
    // &-sideImage {
    //   background: url("https://images.unsplash.com/photo-1551524358-f34c0214781d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80")
    //     center center/cover;
    //   width: 40%;
    //   border-radius: 10px;
    //   @media screen and (max-width : 900px) {
    //     display: none;
    //   }
    // }

    .webView{
      width: 100%;
      // height: 340px;
      object-fit: cover;
      object-position: top;
      border-radius: 56px;
    }

    video{
      width: 100%;
      // height: 340px;
      object-fit: cover;
      object-position: top;
      border-radius: 56px;
      height: 290px;

      @media screen and (max-width: 500px) {
        border-radius: 20px;
      }

      @media screen and (max-width: 767px) {
        
        height: auto;
      }
    }

    .movileView{
      display: none;
    }
    &__contents {
      width: 60%;
      padding: 0px 15px 8px;
      margin: 0 auto;
      min-height: 330px;
      max-height: 400px;
      @media screen and (max-width : 1200px) {
        width: 90%;
        overflow: auto;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
        border-radius: 20px;
        min-height: 310px;
      }
      &__header {
        display: grid;
        padding: 10px 0px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        &-back {
          position: absolute;
          top: 16px;
          right: 36px;
          background: #fff;
          width: 41px;
          height: 41px;
          font-size: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          font-style: normal;
          font-weight: 600;
          cursor: pointer;
        }
        &-logo-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          bottom: 2rem;
          z-index: 0;
        }
        &-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 130px;
          @media screen and (max-width: 1100px) {
            width: 110px;
          }
          @media screen and (max-width: 900px) {
            width: 100px;
          }
          @media screen and (max-width: 400px) {
            width: 90px;
          }
          img {
            width: 100%;
          }
        }
      }
      &__heading {
        text-align: center;
        font-weight: 700;
        font-size: 1.9rem;
        margin-top: 0rem;
        font-style: italic;
        color: #000000;
        font-size: 30px;
        margin-top: -1rem;
        @media screen and (max-width: 1100px) {
          font-size: 1.4rem;
        }
        @media screen and (max-width: 750px) {
          font-size: 1.3rem;
        }
      }
      &__createAccount {
        text-align: center;
        color: #01243C;
        font-size: 0.9rem;
        margin-top: 1rem;
        text-transform: uppercase;
        font-style: italic;
        font-weight: bold;
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &__form {
        margin: auto;
        margin-top: 1.3rem;
        width: 100%;

        @media screen and (max-width : 750px) {

          margin-top: 1rem;
        }
        @media screen and (max-width : 550px) {
          width: 90%;
        }
        &-input {
          margin-top: 20px;
          &-flex {
            display: flex;
            width: 600px;
            @media screen and (max-width : 1130px) {
              width: 500px;
            }
            @media screen and (max-width : 870px) {
              width: 420px;
            }
            @media screen and (max-width : 750px) {
              width: 90%;
            }
            @media screen and (max-width : 550px) {
              width: 100%;
            }
            &-box {
              width: 270px;
              @media screen and (max-width : 1130px) {
                width: 245px;
              }
              @media screen and (max-width : 870px) {
                width: 225px;
              }
              @media screen and (max-width : 750px) {
                width: 100%;
              }
              @media screen and (max-width : 550px) {
                width: 100%;
              }
              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
        &-login-button {
          text-align: center;
          margin-top: 0px;
          font-size: 16px;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0%);
          bottom: 20px;
          font-weight: 600;
          font-style: italic;
          color: #01243C;
          width: 100%;
          @media screen and (max-width : 700px) {
            font-size: 0.8rem;
          }
          span {
            text-decoration: underline;
            cursor: pointer;
            font-size: 20px;
            font-weight: 700;

            @media screen and (max-width : 700px) {
              font-size: 15px;
            }
          }
        }
        &-button {
          padding: 10px 8px;
          width: 100%;
          cursor: pointer;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #01243C;
          border: 1px solid #01243C;
          margin-top: 1.1rem;
          color: white;
          font-size: 17px;
          font-weight: 700;
          text-transform: uppercase;
          font-weight: 700;
          gap: 10px;
          font-style: italic;
          border-radius: 50px;
          cursor: pointer;
          height: 40px;

          img{
            width: 29px;
            height: 29px;
          }

          &.buttonFont{
            font-size: 8px;
            line-height: 10px;
            font-weight: 600;
          }
          @media screen and (max-width : 700px) {
            font-size: 1rem;
            margin-top: 22px !important;
          }

          @media screen and (max-width: 900px) {
            margin-top: 0.7rem;
            padding: 0.4rem;
            font-size: 10px !important;
            line-height: 14px !important;
          }
        }
      }
    }

    @media screen and (max-width : 900px) {
      
      .movileView{
        width: 100%;
        border-radius: 20px;
        // display: block;
      }
  
      .webView{
        display: none;
      }
    }
  }

  .google-btn {
    background-color: white;
    text-transform: capitalize;
    border: 1px solid #01243C;
    color: #01243C;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      padding: 0.4rem;
    }

    div {
      margin-left: 1rem;
    }
  }

  .login-state-form {
    margin-top: 20px;
  }

  .forgot-password-button {
    margin-top: 2.3rem;
  }
}

.buttons-auth {
  display: flex;
  gap: 0.8rem;
  @media screen and (max-width: 900px) {
    flex-direction: row;
    gap: 12px;
  }
}

// .backdrop-white {
//     background: rgba($color: white, $alpha: 0.4);
//     filter: blur(10px);
//     height: 100vh;
//     width: 100vw;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 1;
// }
