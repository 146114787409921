

.WatchItem-item {
    background-color: #fff;
    width: 100%;
    border-radius: 4px;
    padding: 2px;
    display: block;
    transition: transform 0.2s;
    margin: 0 auto;
    cursor: pointer;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-timing-function: ease;
    position: relative;
    overflow: hidden;
    height: 266px !important;
    /* height: 100% !important; */
}

.videoCard{
    padding-bottom: 30px;
  }
.WatchItem-item video {
    object-fit: cover;
}

.timeBox{
    border-radius: 5px;
    padding: 2px 6px;
    background-color: #fff;
    color: #59A4EB;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
    position: absolute;
    right: 40px;
}

.timeBox img{
    width: 12px !important;
}

.WatchItem-item:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 1;
}

.WatchItem-item:hover {
    background-color: #59A4EB;
}

.selected {
    background-color: #59A4EB;
}

.selected .WatchItem-item-checkbox-container {
    border-color: #59A4EB;
    background-color: #59A4EB;
}

.WatchItem-item:hover .WatchItem-item-checkbox-container {
    border-color: #59A4EB;
    background-color: #59A4EB;
}

.WatchItem-item img {
    width: 100%;
    height: 100%;
}


.WatchItem-item-checkbox-container {
    display: flex;
    background: #01243c;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding: 12px;
    border-radius: 4px;
    border: 2px solid #fff;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.WatchItem-item-checkbox-container caption {
    font-size: 1.2rem;
    font-weight: 700;
}

.WatchItem-item-checkbox-container .checkBox {
    width: 15px;
    height: 15px;
    /* display: block; */
    background: #fff;
    border: 2px solid white;
    overflow: hidden;
    position: relative;
}

.fullPreview{
    position: absolute;
    top: 12px;
    left: 12px;
}

.fullPreview button{
    font-size: 14px;
    font-weight: 700;
    color: #fff !important;
    line-height: 35px;
    text-transform: uppercase;
    font-family: "Exo 2";
    font-style: italic;
    padding: 0px 10px;
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 2px solid #FABA06;
    z-index: 99;
    position: relative;
    background: #01243C80 !important;
}

.fullPreview button svg{
    font-size: 26px;
    color: #FABA06;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 3px;
    top: 0px;
    width: 3px;
    height: 7px;
    border: solid #59A4EB;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.selected .checkmark:after {
    display: block;
}


.WatchItem-item-checkbox-container input {
    display: block;
    appearance: none;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
}

.WatchItem-item-checkbox-container input:checked {
    /* background-color: rgb(84, 227, 84); */
    opacity: 1;
}

.WaveItemGrid {
    display: grid;
    gap: 1rem 3rem;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    /* height: 100%; */
}

.WaveContainer {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden scroll;
    max-width: 1000px;
}

@media screen and (max-width : 1000px) {
    .WaveItemGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}


@media screen and (max-width : 750px) {

    .WatchItem-item video {
        object-fit: cover;
    }

    .WatchItem-item:hover {
        background-color: #59A4EB;
        padding: 5px;
    }

    .selected {
        background-color: #59A4EB;
        padding: 5px;
    }

}


@media only screen and (min-width: 1100px) and (max-width: 1900px) {
    .WatchItem-item {
        height: 268px !important;
    }
}

@media screen and (max-width : 1250px) {
    .WatchItem-item {
        height: 100% !important;
    }
}

/* @media screen and (max-width : 767px) {
    .WatchItem-item {
        height: 300px !important;
    }
}

@media screen and (max-width : 750px) {
    .WatchItem-item {
        height: 430px !important;
    }
} */

@media screen and (max-width : 500px) {
    .WatchItem-item {
        height: 244px !important;
    }
}

@media screen and (max-width : 431px) {
    .WatchItem-item {
        height: 254px !important;
    }
}

@media screen and (max-width : 415px) {
    .WatchItem-item {
        height: 244px !important;
    }
}

@media screen and (max-width : 394px) {
    .WatchItem-item {
        height: 234px !important;
    }
}

@media screen and (max-width : 376px) {
    .WatchItem-item {
        height: 224px !important;
    }
}

@media screen and (max-width : 360px) {
    .WatchItem-item {
        height: 218px !important;
    }
}

@media screen and (max-width : 320px) {
    .WatchItem-item {
        height: 196px !important;
    }
}