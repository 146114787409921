.tableAction {
  margin-bottom: 15px;

  :global(.btn) {
    background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(
        180deg,
        rgba(239, 238, 243, 0.27) 0%,
        rgba(239, 238, 243, 0.29) 100%
      ),
      #dde1eb;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    height: 35px;
    line-height: 35px;
    padding: 0px 10px;
    font-size: 14px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 14px;
      margin-right: 8px;
      font-size: 16px;
    }

    &.addBtn {
      color: var(--Main) !important;
    }
    &.upload {
      color: #7f8699 !important;
      position: relative;
      overflow: hidden;

      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .dropMenu {
    background-color: #f3f4f9;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    height: 35px;
    line-height: 35px;
    padding: 0px 10px;
    font-size: 14px;
    color: #7f8699 !important;
    width: 220px;
  }
  .totalCount {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;

    span {
      color: #7f8699;
    }
  }
}

:global(.MuiDataGrid-panel) {
  div,
  label,
  span {
    font-family: "Saira" !important;
    font-size: 14px;
  }
  :global(.MuiSwitch-switchBase) {
    &:global(.Mui-checked) {
      :global(.MuiSwitch-thumb) {
        color: var(--Main);
      }
    }
  }
  :global(.MuiSwitch-track) {
    background: var(--Main) !important;
  }
  :global(.MuiButton-root) {
    color: var(--Main);
  }
}
.dataTable {
  div,
  label,
  span {
    font-family: "Saira" !important;
  }

  div,
  label,
  span {
    font-family: "Saira" !important;
  }

  :global(.MuiDataGrid-toolbarContainer) {
    padding: 8px 10px;
    background: #e3e7ee;
    border-bottom: 1px solid #cacbce;
    display: none;

    :global(.MuiButtonBase-root) {
      color: #7f8699;
      margin: 0px;
      padding: 2px 10px;
      background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
        linear-gradient(0deg, #efeef3, #efeef3),
        linear-gradient(0deg, #efeef3, #efeef3),
        linear-gradient(
          180deg,
          rgba(239, 238, 243, 0.27) 0%,
          rgba(239, 238, 243, 0.29) 100%
        ),
        #dde1eb;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      margin-right: 8px;
      font-family: "Saira";
      font-weight: normal;
      font-size: 12px;

      :global(.MuiButton-startIcon) {
        :global(.MuiSvgIcon-root) {
          font-size: 18px;
        }
      }
    }

    :global(.MuiFormControl-root) {
      background: #fff;
      padding: 5px 10px;
      border-radius: 6px;

      :global(.MuiInput-root) {
        &::after,
        &::before {
          display: none;
        }
        input {
          padding: 0px 0px;
          margin-left: 5px;
          font-size: 14px;
        }
        :global(.MuiIconButton-root) {
          display: none;
        }
      }
    }
  }

  :global(.MuiDataGrid-cellCheckbox) {
    &:focus,
    &:focus-within {
      outline: none !important;
    }
  }
  :global(.MuiCheckbox-root) {
    :global(.MuiDataGrid-toolbarContainer) {
      padding: 8px 10px; /* MuiDataGrid-toolbarContai */
      background: #ff0000;
      border-bottom: 1px solid #cacbce;
      display: none;

      :global(.MuiButtonBase-root) {
        color: #0044ff;
        margin: 0px;
        padding: 2px 10px;
        background: linear-gradient(0deg, #1f8f02, #f3f4f9),
          linear-gradient(0deg, #efeef3, #efeef3),
          linear-gradient(0deg, #efeef3, #efeef3),
          linear-gradient(
            180deg,
            rgba(239, 238, 243, 0.27) 0%,
            rgba(239, 238, 243, 0.29) 100%
          ),
          #dde1eb;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
        margin-right: 8px;
        font-family: "Saira";
        font-weight: normal;
        font-size: 12px;

        :global(.MuiButton-startIcon) {
          :global(.MuiSvgIcon-root) {
            font-size: 18px;
          }
        }
      }

      :global(.MuiFormControl-root) {
        background: #fff;
        padding: 5px 10px;
        border-radius: 6px;

        :global(.MuiInput-root) {
          &::after,
          &::before {
            display: none;
          }
          input {
            padding: 0px 0px;
            margin-left: 5px;
            font-size: 14px;
          }
          :global(.MuiIconButton-root) {
            display: none;
          }
        }
      }
    }
    :global(.MuiDataGrid-cellCheckbox) {
      &:focus,
      &:focus-within {
        outline: none !important;
      }
    }
    :global(.MuiCheckbox-root) {
      :global(.MuiSvgIcon-root) {
        color: #9b9a9a;
        font-size: 23px;
        border-radius: 10px;
      }
    }

    :global(.MuiDataGrid-root) {
      display: block;
      // min-height: 1px !important;
      // max-height: calc(100vh - 185px) !important;
      // overflow: auto;

      :global(.MuiDataGrid-main) {
        :global(.MuiDataGrid-columnHeaders) {
          background-color: #e3e7ee;
          border-radius: 0px;

          :global(.MuiDataGrid-columnHeadersInner) {
            :global(.MuiDataGrid-columnHeader) {
              font-family: "Saira" !important;

              &:focus,
              &:focus-within {
                outline: none;
              }
            }
            /*   :global(.MuiDataGrid-columnSeparator) {
          } */
          }
        }

        :global(.MuiDataGrid-virtualScroller) {
          min-height: 1px;
          max-height: calc(100vh - 270px);
          overflow: auto !important;

          :global(.MuiDataGrid-virtualScrollerRenderZone) {
            :global(.MuiDataGrid-row) {
              font-family: "Saira" !important;

              /*   &:nth-of-type(odd) {
            } */
              &:nth-of-type(even) {
                background-color: #f3f4f9;
              }
              :global(.MuiDataGrid-cell) {
                &:focus {
                  outline: none;
                }
              }
            }
          }
        }
      }

      :global(.MuiDataGrid-footerContainer) {
        display: none;
      }
    }
  }
}
.actionTable {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    :global(.btn) {
      width: 30px;
      height: 30px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      border-radius: 8px;

      &.edit {
        color: #1abf73;
        border-color: #1abf73;
      }
      &.delete {
        color: #e7622e;
        border-color: #e7622e;
      }
      &.unpaid {
        background: #7f8699;
        border-radius: 5px;
        color: #fff;
        font-size: 11px;
        padding: 3px 10px;
        text-decoration: none;
        text-align: center;
        display: inline;
      }
      &.moreDrop {
        width: auto;
        height: auto;

        :global(.form-select) {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}

.bodyBackgound{
  background-color: #01243C;
  height: auto;
  padding-bottom: 20px;
}

.userManagement{

  .userSalesBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0px 5px;

    .Heading{

      h3{
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
      }
    }

    .totalUser{
      display: flex;
      align-items: end;
      gap: 25px;

      .boxUser{
        background-color: #fff;
        border-radius: 10px;
        border: 4px solid #FFFFFF;
        min-width: 230px;
        max-width: 230px;

        h4{
          color: #01243C;
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
          font-style: italic;
          text-transform: uppercase;
          text-align: center;
          padding: 12px 0px;    
          height: 52px;

          text{
            font-size: 12px;
            display: block;

            &.smallText{
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .userCount{
          background-color: #01243C;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          text-align: center;
          padding: 12px 0px;
          color: #fff;
          font-size: 22px;
          font-weight: 600;
          line-height: 18px;
          border-radius: 0px 0px 8px 8px;
        }

        ul{
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: space-between;

          li{
            list-style: none;
            width: 100%;

            &:last-child{

              span{
                border-right: none;
              }
            }

            &:last-child{

              h4{
                border-right: none;
              }
            }

            h4{
              color: #01243C;
              font-size: 15px;
              font-weight: 600;
              line-height: 18px;
              font-family: "Exo 2";
              font-style: italic;
              text-transform: uppercase;
              text-align: center;
              padding: 12px 8px;
              border-right: 2px solid #01243C;
            }

            span{
              background-color: #01243C;
              font-family: "Exo 2";
              font-style: italic;
              text-transform: uppercase;
              text-align: center;
              padding: 12px 0px;
              color: #fff;
              font-size: 22px;
              font-weight: 600;
              line-height: 18px;
              display: block;
              border-right: 2px solid #fff;
            }
          }
        }
      }

      h3{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        line-height: 15px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 6px;
      }
    }

    .filterRemove{
      text-align: right;

      span{
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        color: #fff;
        padding-bottom: 6px;
        display: inline-block;
        cursor: pointer;

        span{
          font-weight: 700;
        }
      }

      .filterRemove{
        position: relative;

        input{
          border-radius: 4px;
          border: 1px solid #FFFFFF99;
          background: #FFFFFF80;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #FFFFFF99;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          padding: 10px 10px 10px 25px;
          min-width: 300px;

          &::placeholder{
            color: #FFFFFF99;
          }

          &:focus{
            box-shadow: none;
            outline: none;
          }
        }

        button{
          position: absolute;
          right: 0px;
          height: 100%;
          border-radius: 4px;
          background-color: #fff;
          padding: 10px;
          color: #2E3848;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 700;
          border: 1px solid #fff;
          cursor: pointer;
        }
      }
    }
  }
}

.tabbingSection{

  .tableResponsive{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    // overflow: hidden;
    height: calc(100vh - 230px);
    // overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
  }

    table{
      border-collapse: separate;
      border-spacing: 0 10px;
      width: 100%;

      thead{
        position: sticky;
        top: 0px;

        tr{

          th{
            background: transparent;
            width: auto;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            font-family: "Exo 2";
            text-transform: uppercase;
            min-width: 170px;
            background-color: #01243C;
            cursor: pointer;

            .dropDownFilter {
              display: inline;

              &:global(.show){
                :global(.dropdown-menu){
                  display: block;
                }
              }
          
              :global(.dropdown-toggle) {
                  border: none;
                  background: transparent;
                  padding: 0;
                  position: relative;
          
                  &::after {
                      display: none;
                  }
                  svg {
                      color: #fff;
                      font-size: 18px;
                      position: absolute;
                      top: -15px;
                      right: -30px;
                  }
              }

              :global(.dropdown-menu){
                display: none;

                ul{
                  list-style: none;
                  background: #fff;
                  border-radius: 10px;
                  min-width: 140px;
                  color: #000;
                  padding: 10px 15px;

                  li{
                    text-align: left;
                    padding: 5px 0px;
                  }
                }
              }
          }
          }
        }
      }

      tbody{

        tr{
          background: #15355A;
          cursor: pointer;
          
          td{
            border: none;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            font-family: "Exo 2";
            text-transform: uppercase;
            text-align: center;

            span{
              width: 30px;
              height: 30px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #fff;
              margin: 0 auto;

              &.yelloIcon{

                svg{
                  color: #FABA06;
                  font-size: 24px;
                }
              }

              &.greenIcon{

                svg{
                  color: #1CBC0F;
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  .userManagement{

    .userSalesBox{

      .totalUser{
        display: block;

        .boxUser{
          margin-bottom: 12px;
          min-width: -webkit-fill-available !important;
          width: 100%;

          ul{
            justify-content: space-between;

            li{
              width: 100% !important;
              min-width: auto !important;
              max-width: none !important;

              h4{
                width: 100% !important;
                min-width: auto !important;
                max-width: none !important;
              }
            }
          }
        }
      }

      .filterRemove{
        text-align: right;
  
        span{
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          color: #fff;
          padding-bottom: 6px;
          display: inline-block;
  
          span{
            font-weight: 700;
          }
        }
  
        .filterRemove{
  
          input{
            min-width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dataTable {
    :global(.MuiDataGrid-toolbarContainer) {
      :global(.MuiFormControl-root) {
        margin-top: 10px;
      }
    }

    :global(.MuiDataGrid-root) {
      :global(.MuiDataGrid-virtualScroller) {
        max-height: calc(100vh - 310px) !important;
      }
    }
  }
}
@media (max-width: 1024px) {

  .userManagement{
    padding-top: 80px;

    .userSalesBox{
      display: block;
      padding: 10px 0px 5px;

      .Heading{

        h3{
          font-size: 28px;
        }
      }

      .totalUser{
        justify-content: end;
        margin-bottom: 12px;

        .boxUser{
          min-width: 130px;

          h4{
            font-size: 13px;   
            height: 46px;

            text{
              font-size: 9px;

              &.smallText{
                height: 26px;
              }
            }
          }

          .userCount{
            font-size: 19px;
          }

          ul{
            display: flex;
            align-items: center;
            text-align: center;

            li{

              h4{
                font-size: 13px;
                min-width: 70px;
                max-width: 70px;
                padding: 12px 2px;
              }

              span{
                font-size: 19px;
              }
            }
          }
        }

        h3{
          font-size: 16px;
        }
      }
    }
  }

  .tabbingSection{

    .tableResponsive{
  
      table{
        border-spacing: 0 7px;
  
        thead{
  
          tr{
  
            th{
              font-size: 15px;
              min-width: 150px;
            }
          }
        }
  
        tbody{
  
          tr{
            
            td{
              font-size: 12px;
  
              span{
                width: 24px;
                height: 24px;
  
                &.yelloIcon{
  
                  svg{
                    font-size: 18px;
                  }
                }
  
                &.greenIcon{
  
                  svg{
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}