.modalOpenStart{
  min-width: 480px;
  max-width: 480px;

  &:focus-visible{
    outline: none;
    overflow: hidden;
  }
    h2{
        font-size: 22px;
        color: #2E3848;
        font-weight: 700;
        margin-bottom: 0px;
        line-height: 26px;
        position: relative;
        font-family: "Exo 2";

        button{
            position: absolute;
            top: 50%;
            transform: translate(0%, -55%);
            right: 0px;
            background: transparent;
            border: none;
            font-size: 32px;
            color: #01243C;
            font-weight: 700;
            font-family: "Exo 2";        
        }
    }

    p{

        .formStart{

            .formInput{
                width: 100%;
                margin-bottom: 12px;
                position: relative;

                :global(.form-control){
                    width: 100%;
                    height: 42px;
                    border: 1px solid #01243C;
                    display: flex;
                    align-items: center;
                    padding: 0px 15px;
                    font-weight: 400;
                    font-size: 18px;
                    color: #01243C;
                    font-family: "Exo 2";
                    line-height: 50px;                

                    &::placeholder{
                        color: #BEBDB8;
                    }

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }

                &.dateIcon{
                    input[type="date"]::-webkit-calendar-picker-indicator {
                      right: 170px;
                      transform: translate(0%, -50%);
                    }
                }

                &.placeHolderColor{

                  :global(.form-control){              

                    &::placeholder{
                      font-size: 18px;
                      color: #01243C;
                    }
                }
                }

                input[type="date"]::-webkit-calendar-picker-indicator {
                  position: absolute;
                  right: 20px;
                  top: 0px;
                  z-index: 9;
                  top: 50%;
                  transform: translate(0%, -50%);
              }

                .inputRight{
                    position: absolute;
                    top: 0px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: 0px;
                    gap: 10px;

                    span{
                        border: 1px solid #01243C;
                        width: 42px;
                        height: 42PX;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        font-weight: 700;
                        color: #2E3848;
                        font-family: "Exo 2";
                        cursor: pointer;

                        &.active{
                            background-color: #01243C;
                            color: #fff;
                        }

                    }
                    button{
                      background: transparent;
                      height: 100%;
                      padding: 0px 30px;
                      border: none;
                      border-left: 1px solid #333;
                      cursor: pointer;
                      color: #EA4A2D;
                      font-size: 13px;
                      font-weight: 700;
                      font-family: "Exo 2";
                      min-width: 163px;
                      max-width: 163px;
                    }
                }
            }

            .select{
                z-index: 999;
                :global(.css-13cymwt-control){
                    height: 42px;
                    border: 1px solid #01243C;
                    font-size: 18px;
                    color: #01243C;
                    font-family: "Exo 2";
                    border-radius: 0px;

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }

                :global(.css-1u9des2-indicatorSeparator){
                  display: none;
                }

                :global(.css-t3ipsp-control){
                  height: 42px;
                    border: 1px solid #01243C !important;
                    font-size: 18px;
                    color: #01243C;
                    font-family: "Exo 2";
                    border-radius: 0px;
                    box-shadow: none !important;

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }

                :global(.css-1nmdiq5-menu){
                  display: flex !important;
                  justify-content: center;
                  margin-top: -2px;
                  border-radius: 0px;
                  box-shadow: none;
                  border: 1px solid #01243c;
                  border-top: 0px;
                  
                  :global(.css-d7l1ni-option){
                    background-color: transparent;
                  }

                  svg{
                    order: 1;
                    width: 40px;
                    height: 40px;
                    margin-right: 0px !important;
                  }

                  span{
                    font-size: 24px;
                    font-weight: 400;
                    color: #01243C;
                    text-transform: uppercase;
                    font-family: "Exo 2";
                    min-width: 90px;
                  }
                }
            }

            .formInputButton{
                
                button{
                    width: 100%;
                    height: 42px;
                    background-color: #01243C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                    font-weight: 700;
                    color: #fff;
                    font-family: "Exo 2";
                    border: 1px solid #01243c;
                    cursor: pointer;
                }
            }
        }
    }
}

.UsermodalOpenStart{
  min-width: 600px;
  max-width: 600px;

  &:focus-visible{
    outline: none;
    overflow: hidden;
  }
    h2{
        font-size: 22px;
        color: #2E3848;
        font-weight: 700;
        margin-bottom: 0px;
        line-height: 26px;
        position: relative;
        font-family: "Exo 2";

        button{
            position: absolute;
            top: 50%;
            transform: translate(0%, -55%);
            right: 0px;
            background: transparent;
            border: none;
            font-size: 32px;
            color: #01243C;
            font-weight: 700;
            font-family: "Exo 2";        
        }
    }
}


.tableResponsive{
    overflow-x: auto;
    background-color: #01243c;
    padding: 12px 16px 0px;
    border-radius: 12px;
    -webkit-overflow-scrolling: touch;
    // height: calc(100vh - 440px);
    height: 250px;
    overflow-y: auto;

    h2{
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        font-family: "Exo 2";
        text-transform: uppercase;
        color: #FFFFFF;
    }
  
    &::-webkit-scrollbar {
      display: none;
  }
  
    table{
      border-collapse: separate;
      border-spacing: 0 6px;
      margin-top: 0px;
      margin-bottom: 0px;
  
      thead{
  
        tr{
  
          th{
            background: transparent;
            width: auto;
            font-size: 9px;
            font-weight: 400;
            line-height: 20px;
            font-family: "Exo 2";
            text-transform: uppercase;
            min-width: 100PX;
            padding: 4px;
  
            .dropDownFilter {
              display: inline;
  
              &:global(.show){
                :global(.dropdown-menu){
                  display: block;
                }
              }
          
              :global(.dropdown-toggle) {
                  border: none;
                  background: transparent;
                  padding: 0;
                  position: relative;
          
                  &::after {
                      display: none;
                  }
                  svg {
                      color: #fff;
                      font-size: 18px;
                      position: absolute;
                      top: -15px;
                      right: -30px;
                  }
              }
  
              :global(.dropdown-menu){
                display: none;
  
                ul{
                  list-style: none;
                  background: #fff;
                  border-radius: 10px;
                  min-width: 140px;
                  color: #000;
                  padding: 10px 15px;
  
                  li{
                    text-align: left;
                    padding: 5px 0px;
                  }
                }
              }
          }
          }
        }
      }
  
      tbody{
  
        tr{
          background: #15355A;
          
          td{
            border: none;
            color: #fff;
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            font-family: "Exo 2";
            text-transform: uppercase;
            text-align: center;
            padding: 8px 6px;
  
            span{
              width: 30px;
              height: 30px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #fff;
              margin: 0 auto;
  
              &.yelloIcon{
  
                svg{
                  color: #FABA06;
                  font-size: 24px;
                }
              }
  
              &.greenIcon{
  
                svg{
                  color: #1CBC0F;
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
}

.freeAccess{
  margin-bottom: 20px;

  ul{
    padding: 10px 10px 6px;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border: 3px solid #01243C;
    border-radius: 10px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
  }

    li{
      list-style: none;

      p{
        font-size: 12px;
        font-weight: 400;
        color: #01243C;
        font-family: "Exo 2";
        text-transform: uppercase;
        display: block;
        margin-bottom: 6px;
        text-align: center;
        position: relative;

        h5{
          font-size: 18px;
          margin-bottom: 0px;
          font-weight: 400;
          font-family: "Exo 2";
          text-transform: uppercase;
        }
        
        :global(.MuiSwitch-sizeMedium){
          padding: 0px;
          position: relative;
          height: auto;

          :global(.MuiSwitch-track){
            height: 28px;
            width: 60px;
            margin: 0 auto;
            background: transparent;
            border: 2px solid #01243c;
            opacity: 1;
            border-radius: 50px;
          }

          :global(.MuiSwitch-switchBase){
            border: 2px solid #01243C;
            padding: 0;
            height: 28px;
            width: 28px;

            :global(svg){
              position: absolute;
            left: 30px;
            font-size: 16px;
            color: #E91B1B;
            }

            &:hover{
              background-color: transparent;
            }
          }
          :global(.Mui-checked){
            background-color: transparent;
            -webkit-transform: translateX(30px);
            -moz-transform: translateX(30px);
            -ms-transform: translateX(30px);
            transform: translateX(30px);

            :global(svg){
              position: absolute;
            left: -22px;
            font-size: 16px;
            color: #1CBC0F;
            }
          }
        }

        :global(.from-control){
          border: 2px solid #01243C;
          padding: 4px 12px 4px 18px;
          border-radius: 7px;
          text-align: center;
        }
        
        input[type="date"]::-webkit-calendar-picker-indicator {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0px;
          z-index: 9;
      }

      input[type="date"] {
        color: #01243C;
        font-size: 14px;
        font-weight: 400;
        font-family: "Exo 2";
        text-transform: uppercase;
    }
    
      input[type="date"]::placeholder {
          color: #01243C;
          font-size: 10px;
      }

        .calIcon{
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%);
          left: 10px;
          font-size: 16px;
        }
      }
    }
  }
}

.subscriptionSection{

  .textName{
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 120px;

    span{
      font-size: 18px;
      color: #01243C;
      font-weight: 400;
      font-family: "Exo 2";
      text-transform: uppercase;
    }
  }

  ul{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #01243C;
    padding: 10px;
    border-radius: 10px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
  }

    li{
      list-style: none;

      p{
        font-size: 11px;
        font-weight: 400;
        color: #fff;
        font-family: "Exo 2";
        text-transform: uppercase;
        display: block;
        margin-bottom: 6px;
        text-align: center;
        position: relative;

        h5{
          font-size: 18px;
          margin-bottom: 0px;
          font-weight: 400;
          font-family: "Exo 2";
          text-transform: uppercase;
        }

        .pastStatus{
          font-weight: 700;
          color: #F37C0F;
        }

        .cancelledStatus{
          font-weight: 700;
          color: #FABA06;
        }

        .inactiveStatus{
          font-weight: 700;
          color: #E91B1B;
        }

        .activeStatus{
          font-weight: 700;
          color: #4DE953;
        }

        span{
          font-size: 13px
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .modalOpenStart{

      h2{
          font-size: 18px;

          button{
            font-size: 22px;
          }
      }
  }


  .UsermodalOpenStart{
    min-width: 90%;
    max-width: 90%;

    h2{
      font-size: 18px;

      button{
        font-size: 22px;
      }
    }
  }

  .subscriptionSection{

    .textName{
      gap: 40px;
      justify-content: space-between;
      margin-bottom: 6px;
  
      span{
        font-size: 16px;
      }
    }
  
    ul{
      margin-bottom: 10px;
  
      li{
        min-width: 140px;
  
        p{
          font-size: 10px;
  
          h5{
            font-size: 18px;
            margin-bottom: 0px;
            font-weight: 400;
            font-family: "Exo 2";
            text-transform: uppercase;
          }
  
          span{
            font-size: 13px
          }
        }
      }
    }
  }

  .freeAccess{
    margin-bottom: 10px;
  
    ul{
  
      li{
        min-width: 140px;
  
        p{
          font-size: 10px;
  
          h5{
            font-size: 16px;
          }
        }
      }
    }
  }
}