.toc-container {
    padding: 3rem 15rem;
    @media screen and (max-width:1400px) {
        padding: 3rem 12rem;
    }
    @media screen and (max-width:1200px) {
        padding: 3rem 8rem;
    }
    @media screen and (max-width:1000px) {
        padding: 3rem 6rem;
    }
    @media screen and (max-width:700px) {
        padding: 3rem 3rem;
    }
    @media screen and (max-width:500px) {
        padding: 3rem 2rem;
    }
    @media screen and (max-width:400px) {
        padding: 3rem 1.2rem;
    }
}


.toc-heading {
    font-size: 2.4rem;
    color: #01243C;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4rem;
    @media screen and (max-width:1200px) {
        font-size: 2.2rem;
    }
    @media screen and (max-width:700px) {
        font-size: 2rem;
        margin-bottom: 3rem;
    }
    @media screen and (max-width:500px) {
        font-size: 1.7rem;
        margin-bottom: 3rem;
    }
    @media screen and (max-width:400px) {
        font-size: 1.5rem;
        margin-bottom: 3rem;
    }
}

.toc-content {
    p {
        margin-top: 0.8rem;
        color:#01243C;
        line-height: 26px;

        a{
            color: #01243C;
            font-weight: 700;
        }
    }
}

.toc-bold {
    font-weight: 700;
}

.toc-content ul{
    margin-bottom: 0px;
    padding-left: 15px;
}

.toc-content ul li {
    list-style: upper-roman;
    padding: 10px 0px;
}


.table-responsive{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
table {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    thead, tbody, th, td, tr{
        border: 0 solid;
        border-color: inherit;
    }

    tr{
        

        th{
            background: #244061;
            text-align: center;
            padding: 10px 10px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            width: 50%;
        }

        td{
            color: #000;
            border: 1px solid #000;
            font-size: 13px;
            padding: 12px 15px;
            line-height: 24px;
            vertical-align: sub;

            ul{
                padding-left: 0px;

                li{
                    padding-top: 0px;
                }
            }
        }
    }
}