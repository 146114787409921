.session {
    background-color: #fff;
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    /* height: 100%; */
}

.session__sidebar {
    width: 25vw;
}

.session-container {
    background-color: #01243C;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: relative;
}


.overlay__session__sidebar {
    min-height: 100vh;
    width: 100vw;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 50;
}

@media screen and (min-width:750px) {
    .overlay__session__sidebar {
        display: none;
    }
}


.sidebar__open {
    position: absolute;
    width: fit-content;
    width: 20px;
    background: white;
    border-radius: 10px;
    height: 20vh;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    display: none;

}

.watch {
    color: white;
    font-style: italic;
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;    
}
.watchContainer {
    padding: 20px 3rem;
    width: 100%;
    /* margin: auto; */
    max-width: 1000px;    
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-bottom: 10px;
}



.watchContainer button{
    background-color: #234A6D;
}




.watchContainer button:hover{
    background-color: #fff;
    transition: .5s;
    color: #234A6D;
}

.HeadingContainer button:first-child{
    background-color: transparent !important;
  }

  .HeadingContainer button:first-child:hover{
    background-color: transparent !important;
  }

  .buttonBorderColor1{
    border: 2px solid #59A4EB !important;
    position: relative !important;
    overflow: hidden;
  }

  .buttonBorderColor1 .DayPass{
    background-color: #59A4EB;
    padding: 0px 8px;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    font-style: italic;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  .buttonBorderColor2{
    border: 2px solid #FBBC05 !important;
    position: relative !important;
    overflow: hidden;
  }

  .buttonBorderColor2 .DayPass{
    background-color: #FBBC05;
    padding: 0px 8px;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    font-style: italic;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }


.defalutBtn {
    border: 2px solid #012035 !important;
}

@media screen and (max-width:750px) {
    .sidebar__open {
        display: flex;
        position: fixed;
        z-index: 9;
        width: 30px;
    }
    .watchContainer {
        padding:2rem 1rem;
        padding-bottom: 90px !important;
    }
    .session {
        overflow-y: auto;
        height: 100%;
    }

    .session__sidebar {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 70vw;
        height: 100vh;
        z-index: 99999;
        background-color: white;
    }

    .session-container {
        width: 100vw;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        min-height: 100vh;
        height: fit-content;

    }
}

@media screen and (max-width:1350px) { 
    .session__sidebar {
        width: 30vw;
    }
}
@media screen and (max-width:1150px) { 
    .session__sidebar {
        width: 32vw;
    }
}
@media screen and (max-width:600px) { 
    .session__sidebar {
        width: 80vw;
    }    
}


.hidden {
    display: none;
}

.show {
    display: block;
}