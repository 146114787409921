.dropdown {

    .dropdown-header {
        padding: 8px 40px;
        font-size: 17px;
        display: flex;
        gap: 20px;
        color: #01243c;
        font-weight: 700;
    }

    .dropdown-options {
        padding: 8px 44px;
        font-size: 14px;
        line-height: 1.5;
        color: #01243c;
        font-weight: 700;
        font-weight: 700;
        text-transform: uppercase;
        font-style: italic;

        .dropdownMenu {
            padding: 3px 0px;
        }

    }
}

.locationDropdown {
    letter-spacing: 0.00938em;
    align-items: center;
    text-transform: uppercase;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid lightgray;
    font-style: italic;

    &:last-child {
        border: none;
    }
}

ul {
    li {
        text-transform: uppercase;
        font-family: "Exo 2" !important;
        font-style: italic;
    }
}

.navbar {
    padding: 0 6rem; //tb rl

    @media screen and (max-width : 1000px) {
        padding: 0.6rem 4rem;
    }

    @media screen and (max-width : 600px) {
        padding: 0.3rem 2rem;
    }

    width: 100vw;
    // position: relative;
    z-index: 100;
    position: fixed;

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .hamburger-menu-icon {
            padding: 0.3rem;
            color: white;

        }

    }

    &.sticky {
        background-color: #01243C;
        padding-top: 0px;
        padding-bottom: 0px;
        transition: 0.5s;

        // .navbar__link{
        //     color: #01243C;

        //     &.active{

        //         &::before{
        //             background-color: #01243C;
        //         }
        //     }
        // }

        // .profileDropdown{

        //     .HeaderDropdown{

        //         span{
        //             color: #01243C;
        //         }
        //     }
        // }

        // .navbar__logo {
        //     filter: invert(91%) sepia(81%) saturate(756%) hue-rotate(168deg) brightness(100%) contrast(104%);
        // }

        // .hamburger-menu-icon{
        //     color: #01243C;
        // }
    }


    &__logo {
        width: 125px;

        @media screen and (max-width:700px) {
            width: 95px;
        }
    }

    &__links {
        display: flex;
        align-items: center;
    }

    &__link {
        &:nth-child(5) {
            margin-left: -2rem;
        }

        margin-left: 2rem;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        color: white;
        font-style: italic;
        position: relative;

        &.active {

            &:before {
                content: "";
                position: absolute;
                height: 2px;
                background: #FFFFFF99;
                width: 75%;
                bottom: -7px;
                left: 50%;
                transform: translate(-50%, 0%);
            }

        }

        @media screen and (max-width:991px) {
            display: none;
        }
    }

    &__menu {
        display: none;

        @media screen and (max-width:991px) {
            display: block;
            color: white;
        }

    }
}



.profileDropdown {
    margin-left: 35px;

    .HeaderDropdown {
        position: relative;

        span {
            margin-left: 2rem;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 20px;
            font-weight: 600;
            color: white;
            font-style: italic;
            position: relative;
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .dropdownMenu {
            position: absolute;
            min-width: 210px;
            right: 0px;
            top: 50%;
            transform: translate(20%, 10%);

            .dropdownBox {
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
                max-width: calc(100% - 32px);
                max-height: calc(100% - 32px);
                padding: 12px;

                a {
                    display: block;
                    margin: 8px 0px;
                    white-space: nowrap;
                    width: 100%;
                    padding: 4px 0px;
                }
            }
        }
    }

    .MuiBox-root {
        position: relative;

        .MuiFormControl-fullWidth {
            flex-direction: row;
            display: flex;
            align-items: center;

            .notranslate {
                display: none;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translate(-50%, -50%);
            font-size: 20px;
            color: #fff;
        }

        .MuiFormControl-fullWidth {

            .MuiOutlinedInput-root {

                fieldset {
                    border: none;
                }
            }
        }

        .MuiSvgIcon-fontSizeMedium {
            color: #fff;
        }
    }

    .MuiSelect-icon {
        font-size: 24px;
        color: #fff;
    }
}

@media screen and (max-width:991px) {
    .profileDropdown {
        display: none;
    }
}

.navbar-background-normal {
    background-color: #01243C;
    position: unset;
}