@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.root-container:not(.tw-enabled) * {
  box-sizing: border-box;

  $primary-color: #01243c;
  $secondary-color: "#5E6979";
  $header-bg: "#DCD5DC";

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  ul {
    margin: 0;
    padding: 0;
  }
}

html {
  scroll-behavior: smooth !important;
  * {
    box-sizing: border-box;
  }
}

html:has(.landing-page-section) {
  overflow-x: hidden !important;
}

.swal2-container {
  z-index: 1000000 !important;
}

a {
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
}

body {
  margin: 0;
}
body,
html {
  font-family: "Exo 2", sans-serif;
}

.container {
  padding-left: 6rem;
  padding-right: 6rem;
  @media screen and (max-width: 1300px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media screen and (max-width: 1000px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @media screen and (max-width: 700px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @media screen and (max-width: 500px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 400px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 7px;
  background-color: #01243c;
  /* display: none; */
}

body::-webkit-scrollbar-thumb {
  background-color: #01243c;
  border: 2px solid #01243c;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar {
  width: 7px;
  background-color: #01243c;

  /*  */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #01243c;
  border: 2px solid #01243c;
}

// No scrollbar

.scrollbar-none::-webkit-scrollbar-track,
.scrollbar-none::-webkit-scrollbar,
.scrollbar-none::-webkit-scrollbar-thumb {
  display: none;
}

.heading-all {
  font-size: 3rem !important;
  font-weight: 600 !important;
  font-style: italic !important;
  text-transform: uppercase !important;
  @media screen and (max-width: 1150px) {
    font-size: 2.4rem !important;
  }
  @media screen and (max-width: 1000px) {
    font-size: 2rem !important;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.8rem !important;
  }
}

.pointer {
  cursor: pointer !important;
}

.date-input {
  padding: 0.4rem;
  &-text {
    font-weight: 600;
  }
  width: 100%;
  input {
    width: 100%;
    margin-top: 0.8rem;
    padding: 0.65rem 0.4rem;
  }
}