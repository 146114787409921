.VictoryContainer {
  display: flex;
  align-items: flex-end;

  & > svg {
    width: unset;
    height: unset !important;

    path {
      stroke-linecap: round;
    }
  }
}
