.wavesSeclect {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wavesSeclect .btn{
  background: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  min-width: 300px;
  padding: 12px;
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
  height: 65px;
  font-family: "Exo 2", sans-serif;
}


.wavesSeclect .btn:hover{
  background: #fff;
  transition: .5s;
}

.wavesSeclect .btn:hover span, .wavesSeclect .btn:hover b{
  color: #102D48;
  transition: .5s;
}

.wavesSeclect .btn:hover img{
  filter: invert(91%) sepia(62%) saturate(1553%) hue-rotate(184deg) brightness(91%) contrast(90%);
}

.wavesSeclect .btn span{
    color: #fff;
    font-size: 34px;
    font-weight: 700;
    font-style: italic;
}

.wavesSeclect .btn b{
  color: #59A4EB;
}

.wavesSeclect .btn img{
  width: 27px;
}

.wavesSeclect .nextButton .btnNext{
  background-color: #fff !important;
  display: flex;
  align-items: center;
  gap: 14px;
  border: 1px solid #fff;
  border-radius: 8px;
  height: 65px;
  padding: 10px 20px;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  font-family: "Exo 2", sans-serif;
}

.wavesSeclect .nextButton .btnNext img{
  width: 23px;
}

.faqsButton{
  text-align: center;
  margin-bottom: 10px;
  margin-left: 70px;
}

.faqsButton button{
  background: transparent;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  border-radius: 8px;
  padding: 6px 10px;
  gap: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  min-width: 120px;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.faqsButton button img{
  width: 22px;
}

.faqsButton button:hover img{
  filter: invert(91%) sepia(62%) saturate(1553%) hue-rotate(184deg) brightness(91%) contrast(90%);
}


.faqModalStart{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faqModalStart .background{
  background: #00000094;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: fixed;
}

.faqModalStart .faqModal{
  background-color: #01243C;
  border-radius: 10px;
  padding: 18px 22px;
  position: relative;
  z-index: 111;
  min-width: 60%;
  border: 1px solid #ffffff;
  height: 70%;
  width: 70%;
}

.faqModalStart .faqModal .faqHeading{
  position: relative;
  margin-bottom: 20px;
}

.faqModalStart .faqModal .faqHeading h2{
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0px;
  text-align: center;
}

.faqModalStart .faqModal .faqHeading .closeFaq{
  cursor: pointer;
  color: #FFFFFF;
  font-size: 22px;
  font-weight: 400;
  font-style: italic;
  position: absolute;
  top: 4px;
  left: 0px;
  border: none;
  background-color: transparent;
  padding: 0px;
}

.faqModalStart .faqModal .faqBox{
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 60px;
  overflow: hidden;
  height: calc(60vh - 68px);
  overflow-x: hidden;
  overflow-y: auto;
}

.faqModalStart .faqModal .faqBox .boxText h3{
  color: #000;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
  text-decoration: underline;
}
.faqModalStart .faqModal .faqBox .boxText h4{
  color: #000;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
}
.faqModalStart .faqModal .faqBox .boxText p{
  color: #000;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
}

.watch {
    color: white;
    font-style: italic;
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;    
}
.watchContainer {
    padding: 3rem;
    width: 100%;
    /* margin: auto; */
    max-width: 1000px;    
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.HeadingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }
  
  .header {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: 1.2rem;
    text-align: center;
  }

  .header .spanFontSize{
    font-size: 16px;
  }
  .HeaderFirst {
    flex: 0 0 150px;
    justify-content: start !important;
    padding-left: 0px !important;
  }
  .HeaderLast {
    /* display: flex;
    flex-direction: column;
    align-items: end; */
    flex: 0 0 25%px;
    font-size: 1.1rem;
  }
  .HeaderLast h2 {
    font-size: 1.1rem;
    text-align: end;
  }

  .HeaderLast h3 {
    font-size: 1.1rem;
    font-weight: 500;
    text-align: end;
  }

  .strikethrough {
    position: relative;
  }
  .strikethrough:before {
    position: absolute;
    content: "";
    left: -5px;
    top: 50%;
    right: -5px;
    border-top: 3px solid;
    border-color: red;
    
    -webkit-transform:rotate(-25deg);
    -moz-transform:rotate(-25deg);
    -ms-transform:rotate(-25deg);
    -o-transform:rotate(-25deg);
    transform:rotate(-25deg);
  }


  

  @media screen and (max-width : 800px) {
    .faqsButton button img{
      width: 19px;
    }

    .header .spanFontSize{
      font-size: 9px;
    }
    .header {
        font-size: 11px;
        margin-top: 10px;
      }

      .faqsButton{
        margin-right: 12px;
      }

      .faqsButton button{
        justify-content: space-between;
        min-width: auto;
        font-size: 18px;
      }

      .HeaderLast {
          max-width: 110px;
      }

      .HeaderFirst {
        flex: 0 0 10%;
        font-size: 0.8rem !important;
      }    
      .HeaderLast h2 {
        font-size: 0.8rem;
      }
    
      .HeaderLast h3 {
        font-size: 0.8rem;
      }

      .wavesSeclect{
        gap: 17px;
        position: fixed;
        left: 50%;
        bottom: 0px;
        width: 100%;
        transform: translate(-50%, 0%);
        z-index: 9999;
        padding: 1rem 1rem;
        background: #01243c;
      }

      .wavesSeclect .btn{
        min-width: 220px;
        font-size: 16px;
        padding: 6px 6px;
        gap: 0.6rem;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        max-width: 220px;
      }

      .wavesSeclect .btn img{
        width: 20px;
      }

      .wavesSeclect .btn span{
        font-size: 28px;
      }

      .wavesSeclect .nextButton .btnNext{
        gap: 0.9rem;
        padding: 10px 6px;
        font-size: 16px;
        height: 55px;
        justify-content: space-between;
        min-width: 90px;
      }
      .wavesSeclect .nextButton .btnNext img{
        width: 18px;
      }
    
  }