.Profile{
    padding: 1rem;
    text-transform: uppercase;
    width: 100%;

    @media screen and (min-width: 48rem) {
        padding: 2.7rem 2rem;
    }
}

.Profile .profileHeading{
    text-align: center;
    font-style: italic;
    color: #fff;
    margin-bottom: 60px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    @media screen and (min-width: 48rem) {
        font-size: 2.6875rem;
    }
}

.Profile .ProfileUser{
    margin: 120px auto auto auto;
    width: 70%;
}

.Profile .ProfileUser .statusContent{
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 40px;
}

.Profile .ProfileUser .statusContent h3{
    background-color: #FBBC05;
    display: block;
    color: #01243C;
    min-width: 220px;
    text-align: center;
    padding: 12px 15px;
    font-size: 22px;
    font-weight: 700;
    font-style: italic;
    border-radius: 0px 12px 10px 0px;
    font-family: "Exo 2";
}

.Profile .ProfileUser .statusContent h4{
    margin: 0px;
    width: 100%;
    text-align: center;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 500;
    font-style: italic;
    padding: 0px 20px;
    color: #fff;
    font-family: "Exo 2";
}

.Profile .ProfileUser .statusContent h4 span{
    font-weight: 700;
    color: #FBBC05;
}

.Profile .ProfileUser .autoRenewal{
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    border-radius: 12px 12px 12px 12px;
    /* overflow: hidden; */
    position: relative;
    width: 70%;
    margin: auto auto 40px auto;
}

.Profile .ProfileUser .autoRenewal h3{
    background-color: #FBBC05;
    display: block;
    color: #01243C;
    min-width: 250px;
    text-align: center;
    padding: 12px 15px;
    font-size: 17px;
    font-weight: 700;
    font-style: italic;
    border-radius: 10px 12px 10px 10px
}

.Profile .ProfileUser .autoRenewal h4{
    margin: 0px;
    width: 100%;
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    font-style: italic;
    padding: 0px 20px;
    color: #fff;
}

.Profile .ProfileUser .autoRenewal button{
    border: 3px solid #EA4A2D;
    background-color: transparent;
    color: #EA4A2D;
    padding: 12px 15px;
    border-radius: 0px 10px 10px 0px;
    height: 49px;
    top: -2px;
    position: absolute;
    right: -99px;
    min-width: 100px;
    cursor: pointer;
}
.Profile .ProfileUser .autoRenewal button:hover{
    border: 3px solid #EA4A2D;
    background-color: #EA4A2D;
    color: #fff;
}

.Profile .ProfileUser .autoRenewal .colorYellow{
    border: 3px solid #FBBC05;
    color: #FBBC05;
}

.Profile .ProfileUser .autoRenewal.borderRadius{
    border-radius: 12px;
}

.Profile .ProfileUser .getSurfEyeButton{
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;
}

.Profile .ProfileUser .getSurfEyeButton button{
    display: block;
    color: #fff;
    min-width: 280px;
    text-align: center;
    padding: 15px 15px;
    font-size: 17px;
    font-weight: 600;
    font-style: italic;
    border-radius: 10px 12px 10px 10px;
    background-color: transparent;
    border: 3px solid #FBBC05;
    cursor: pointer;
}
.Profile .ProfileUser .getSurfEyeButton button:hover{
    color: #01243C;
    background-color: #FBBC05;
    border: 3px solid #FBBC05;
}
.Profile .ProfileUser .getSurfEyeButton button:hover span{
    color: #01243C;
}

.Profile .ProfileUser .getSurfEyeButton button span{
    font-weight: 700;
    color: #FBBC05;
}


.ModalStart {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ModalStart .background {
    background: #0000004a;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
}
.ModalStart .ModalBox {
    background-color: #fbbc05;
    border-radius: 10px;
    padding: 18px 22px;
    position: relative;
    z-index: 99999;
    min-width: 60%;
    border: 1px solid #fff;
    height: auto;
    width: 70%;
}
.ModalStart .ModalBox .Heading {
    position: relative;
    margin-bottom: 20px;
}
.ModalStart .ModalBox .Heading h2 {
    color: #01243C;
    font-size: 32px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 0px;
    text-align: center;
}

.ModalStart .ModalBox .Heading .closeFaq{
    cursor: pointer;
    color: #01243C;
    font-size: 22px;
    font-weight: 400;
    font-style: italic;
    position: absolute;
    top: 4px;
    left: 0px;
    border: none;
    background-color: transparent;
    padding: 0px;
}

.ModalStart .ModalBox .Box{
    background-color: #01243C;
    border-radius: 10px;
    padding: 30px 30px;
}

.ModalStart .ModalBox .Box .boxText{
    text-align: center;
}

.ModalStart .ModalBox .Box .boxText h3{
    color: #fff;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
}

.ModalStart .ModalBox .Box .boxText h3 span{
    color: #FBBC05;
}

.ModalStart .ModalBox .Box .boxText h4{
    color: #fff;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
}

.ModalStart .ModalBox .Box .boxButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
}
.ModalStart .ModalBox .Box .boxButton{
    margin-top: 40px;
}

.ModalStart .ModalBox .Box .boxButton button{
    padding: 12px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    min-width: 160px;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 6px;
}

.ModalStart .ModalBox .Box .boxButton .themeColor{
    color: #FBBC05;
    border-color: #FBBC05;
}

.ModalStart .BackGroundChnage .ModalBox{
    width: 100%;
    background-color: #59A4EB;
}

.ModalStart .BackGroundChnage .ModalBox .Heading h2{
    color: #fff;
}


@media (max-width: 767px) { 
    .Profile .ProfileUser{
        width: 100%;   
    }
    .Profile .ProfileUser .statusContent h3{
        min-width: 130px;
        font-size: 18px;
    }
    .Profile .ProfileUser .statusContent h4{
        font-size: 18px;
    }
    .Profile .ProfileUser .autoRenewal h3 {
        font-size: 14px;
        min-width: 150px;
    }
    .Profile .ProfileUser .autoRenewal {
        width: 76%;
        margin: 0;
        margin-bottom: 15px;
    }
    .Profile .ProfileUser .autoRenewal h4 {
        font-size: 14px;
    }
    .Profile .ProfileUser .autoRenewal button {
        border: 3px solid #EA4A2D;
        padding: 9px 10px;
        height: 45px;
        top: -2px;
        right: -79px;
        min-width: 70px;
    }
    .borderRadius {
        width: 100% !important; 
    }


    .ModalStart .ModalBox {
        min-width: 90%;
        width: 90%;
    }
    .ModalStart .ModalBox .Heading h2 {
        font-size: 20px;
        padding-top: 40px;
    }
    
    .ModalStart .ModalBox .Box{
        padding: 15px 15px;
    }
    
    .ModalStart .ModalBox .Box .boxText{
        text-align: center;
    }
    
    .ModalStart .ModalBox .Box .boxText h3{
        line-height: 22px;
        font-size: 18px;
        margin-bottom: 12px;
    }
    
    .ModalStart .ModalBox .Box .boxText h4{
        margin-bottom: 10x;
        font-size: 15px;
        line-height: 20px;
    }
    
    .ModalStart .ModalBox .Box .boxButton{
        gap: 10px;
    }
    .ModalStart .ModalBox .Box .boxButton{
        margin-top: 24px;
    }

    .ModalStart .ModalBox .Box .boxButton button{
        font-size: 14px;
        min-width: 110px;
    }
}

