.sidebar{

    .socialIconShow{

        &:hover {
            
            .hoverBody{
                display: block;
                margin-top: 12px;
            }
        }

        .hoverBody{
            display: none;

            .hoverBodyIn{
                display: flex;
                align-items: center;
                gap: 12px;
                // position: absolute;
                // top: 0px;
                justify-content: space-between;
                // transform: translate(0% , -100%);
                width: 100%;
                // left: 0px;
    
                .emailIcon{
    
                    a{
                        padding: 4px 5px 6px;
                        border-radius: 10px;
                        display: block;
                        width: 100%;
                        border: 1.5px solid #001523;
                        text-align: center;
                        color: #001523;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-style: italic;
                        font-size: 20px;
                        transition: .5s;
                        min-width: 105px;
    
                        svg{
                            font-size: 30px;
                            margin-bottom: 5px;
                        }
    
                        h6{
                            margin: 0;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.sidebar {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    height: 100%;
    justify-content: center;
    &-container {
        padding: 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        position: relative;
        // justify-content:center ;
        // height: 100vh;
        @media screen and (max-width:800px) {
            padding: 1rem;
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55%;
        margin: auto;
        img {
            width: 100%;
        }
    }
    &__counts {
        margin-top: 3rem;
        display: flex;
        width: 100%;
        &-item {
            flex: 1;
            padding: 1rem 1.2rem;
            @media screen and (max-width:1000px) {
                padding: 0.6rem 1rem;
            }
            text-align: center;
            &__heading {
                font-size: 1.3rem;
                font-weight: 700;
                @media screen and (max-width:1000px) {
                    font-size: 1rem;
                }
                font-style: italic;
                color: #01243C;
                @media screen and (max-width:700px) { 
                    font-size: 1rem;
                }
            }
            &__value {
                font-size: 1.7rem;
                font-weight: 700;
                font-style: italic;
                @media screen and (max-width:1000px) { 
                    font-size: 1.4rem;
                }
                @media screen and (max-width:800px) { 
                    font-size: 1.2rem;
                }
                margin-top: 0.3rem;
                color: #01243C;
            }
            &:first-child {
                border-top: 1px solid black;
                border-left: 1px solid black;
                border-bottom: 1px solid black;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:nth-child(2) {
                border-top: 1px solid black;
                border-right: 1px solid black;
                border-left: 1px solid black;
                border-bottom: 1px solid black;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
    &__nav {
        margin-top: 1.5rem;
        width: 100%;
        &__button {
            width: 100%;
            padding: 0.8rem;
            font-size: 1.2rem;
            font-weight: 700;
            font-style: italic;
            border-radius: 7px;
            cursor: pointer;
            background-color: white;
            border: 1px solid #01243C;
            margin-top: 1rem;
            color: #01243C;
            transition: all 0.1s ease-in;
            text-align: center;        
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:hover {
                background: #01243C13;
            }
        }
    }
    &__buttons {
        width: 100%;
        margin-top: 1.5rem;
        width: 100%;
        margin-top: 1.5rem;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding: 2rem;
    }
   
}



.sidebar__nav__active {
    background-color: #01243C;
    color: white;
    &:hover {
        background-color: #01243Ce9;
    }
    &:visited {
        color: white;
    }
}

// .sidebar__nav__button-contact {
//     padding: 0.4rem;
// }

.sidebar__arrow {
    position: absolute;
    display: none;
    width: 100%;
    z-index: 100;
    @media screen and (max-width:750px) {
        display: block;
    }
    padding: 1rem;
}

.sideBar_iconSet{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100%;

    &.sidebar__nav__active {

        .sidebar_icon {
            filter: invert(99%) sepia(23%) saturate(2%) hue-rotate(344deg) brightness(112%) contrast(100%);
        }

    }

    .sidebar_icon {
        width: 23px;
        filter: invert(-4%) sepia(6%) saturate(7041%) hue-rotate(163deg) brightness(94%) contrast(107%);
    }
}

@media (max-width: 769px) {
    .sidebar {
        
        &__buttons {
            bottom: 100px;
        }
       
    }

    .iconSet{
        font-size: 12px !important;
    }
 }