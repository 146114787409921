$sub-color: grey;

.login-with-google-button{
    width: 100% ;
    height: 100% ;
    display: flex     ;
    align-items: center   ;
    justify-content: center   ;
    box-shadow: none !important;
}

.auth {
    background-color: white;
    // height: 100vh;
    &__container {
        display: flex;
        // flex-direction: row-reverse;
        height: 100%;
    }
    // &-sideImage {
    //     background: url('https://images.unsplash.com/photo-1551524358-f34c0214781d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80') center center/cover;
    //     width: 40vw;
    //     border-radius: 10px;
    //     @media screen and (max-width : 750px) {
    //         display: none;
    //     }
    // }
    &__contents {
        width: 60vw;
        @media screen and (max-width : 750px) {
            width: 100%;
        }
        &__header {
            display: grid;
            padding: 0.8rem 2rem;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            &-back {
                
            }
            &-logo-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                bottom: 2rem;
                z-index: 0;
            }
            &-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 170px;
                @media screen and (max-width:1100px) {
                    width: 160px;
                }
                @media screen and (max-width:900px) {
                    width: 140px;
                }
                @media screen and (max-width:400px) {
                    width: 130px;
                }
                img {
                    width: 100%;
                }
            }
        }
        &__heading {
            text-align: center;
            font-weight: 700;
            font-size: 1.9rem;
            margin-top: 0rem;
            font-style: italic;
            color: #01243C;
        }
        &__createAccount {
            text-align: center;
            color: #01243C;
            font-size: 1.2rem;
            margin-top: 1rem;
            text-transform: uppercase;
            font-style: italic;
            font-weight: bold;
            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &__form {
            margin: auto;
            margin-top: 3rem;
            width: 600px;
            @media screen and (max-width : 1130px) {
                width: 500px;
            }
            @media screen and (max-width : 870px) {
                width: 420px;
            }
            @media screen and (max-width : 750px) {
                width: 80vw;
            }
            @media screen and (max-width : 550px) {
                width: 90vw;
            }
            &-input {
                margin-top: 1.1rem;
                &-flex {
                    display: flex;
                    width: 600px;
                    @media screen and (max-width : 1130px) {
                        width: 500px;
                    }
                    @media screen and (max-width : 870px) {
                        width: 420px;
                    }
                    @media screen and (max-width : 750px) {
                        width: 80vw;
                    }
                    @media screen and (max-width : 550px) {
                        width: 90vw;
                    }
                    &-box {
                        width: 295px;
                        @media screen and (max-width : 1130px) {
                            width: 245px;
                        }
                        @media screen and (max-width : 870px) {
                            width: 225px;
                        }
                        @media screen and (max-width : 750px) {
                            width: 40vw;
                        }
                        @media screen and (max-width : 550px) {
                            width: 48vw;
                        }
                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }
            &-login-button {
                text-align: center;
                margin-top: 1.1rem;
                font-size: 1.2rem;
                @media screen and (max-width : 700px) {
                   font-size: 1rem;
                }
                font-weight: 700;
                font-style: italic;
                color: #01243C;
                span {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            &-button {
                padding: 0.6rem;
                width: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #01243C;
                margin-top: 1.1rem;
                color: white;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 700;
                @media screen and (max-width : 700px) {
                    font-size: 1rem;
                 }
                font-style: italic;
                border-radius: 5px;
                cursor: pointer;

            }
        }
    }
}

.google-btn {
    background-color: white;
    text-transform: capitalize;
    border: 1px solid #01243C;
    color: #01243C;
    cursor: pointer;

 
    div {
        margin-left: 1rem;
    }

    
}

.login-state-form{
    margin-top: 2.5rem;

}

.forgot-password-button {
    margin-top: 2.3rem;
}

.select-input {
    background: none;
    background-color: white;
    color: $sub-color;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border: 1px solid $sub-color;
    border-radius: 3px;
    font-size: 1rem;
    font-family: 'Exo 2';

    option {
        font-family: 'Exo 2';
    }
}