.controller {
    display: flex;
    color: white;
    border: 1px solid white;
    width: 100%;
    margin: auto;
    border-radius: 6px;
    border: 2px solid #01243C;
    background: white;


    &-item {
        display: flex;
        padding: 0.5rem 1rem;
        @media screen and (max-width : 700px) {
            padding: 0.5rem 0.5rem;
            font-size: 1rem;
        }
        @media screen and (max-width : 500px) {
            padding: 0.5rem 0.1rem;
            font-size: 0.8em;
   
        }
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 700;
        font-style: italic;
        background: white;
        color: #01243C;
        // justify-content: space-between;
        align-items: center;
        text-align: center;
        svg {
            position: relative;
            font-size: 2.2rem;
            top: 3px;
            left: 20px;
            @media screen and (max-width:650px) {
                left: 10px;
                font-size: 1.8rem;
            }
            @media screen and (max-width:400px) {
                left: 4px;
                font-size: 1.5rem
            }
        }
        text-transform: uppercase;
      
        &:first-child {
            flex : 1;
            border-right: 1px solid #01243C;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }
        &:nth-child(2) {
            flex :1
        }
        &:nth-child(3) {
            flex : 1;
            background-color: #01243C;
            border: 1px solid white;
            color: white;
            border-radius: 6px;
            div {
                margin-right: 0rem;
            }

        }
    }
}