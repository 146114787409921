.bodyBackgound{
  background-color: #01243C;
  height: 100vh;
  padding-bottom: 20px;
}

.userManagement{

  .userSalesBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0px 40px;

    .Heading{

      h3{
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
      }
    }

    .filterRemove{
      text-align: right;

      span{
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        color: #fff;
        padding-bottom: 8px;
        display: inline-block;
        cursor: pointer;

        span{
          font-weight: 700;
        }
      }

      .filterRemove{
        position: relative;

        button{
          height: 100%;
          border-radius: 4px;
          background-color: #fff;
          padding: 10px 30px;
          color: #2E3848;
          font-family: "Exo 2";
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 700;
          border: 1px solid #fff;
          color: #2E3848;
          cursor: pointer;
        }
      }
    }
  }
}

.tabbingSection{

  .tableResponsive{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    // overflow: hidden;
    height: calc(100vh - 230px);
    // overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
  }

    table{
      border-collapse: separate;
      border-spacing: 0 10px;
      width: 100%;

      thead{
        position: sticky;
        top: 0px;

        tr{

          th{
            background: transparent;
            width: auto;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            font-family: "Exo 2";
            text-transform: uppercase;
            cursor: pointer;
            background-color: #01243C;

            .dropDownFilter {
              display: inline;

              &:global(.show){
                :global(.dropdown-menu){
                  display: block;
                }
              }
          
              :global(.dropdown-toggle) {
                  border: none;
                  background: transparent;
                  padding: 0;
                  position: relative;
          
                  &::after {
                      display: none;
                  }
                  svg {
                      color: #fff;
                      font-size: 18px;
                      position: absolute;
                      top: -15px;
                      right: -30px;
                  }
              }

              :global(.dropdown-menu){
                display: none;

                ul{
                  list-style: none;
                  background: #fff;
                  border-radius: 10px;
                  min-width: 140px;
                  color: #000;
                  padding: 10px 15px;

                  li{
                    text-align: left;
                    padding: 5px 0px;
                  }
                }
              }
          }
          }
        }
      }

      tbody{

        tr{
          background: #15355A;
          cursor: pointer;
          
          td{
            border: none;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            font-family: "Exo 2";
            text-transform: uppercase;
            text-align: center;

            span{
              width: 30px;
              height: 30px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #fff;
              margin: 0 auto;

              &.yelloIcon{

                svg{
                  color: #FABA06;
                  font-size: 24px;
                }
              }

              &.greenIcon{

                svg{
                  color: #1CBC0F;
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dataTable {
    :global(.MuiDataGrid-toolbarContainer) {
      :global(.MuiFormControl-root) {
        margin-top: 10px;
      }
    }

    :global(.MuiDataGrid-root) {
      :global(.MuiDataGrid-virtualScroller) {
        max-height: calc(100vh - 310px) !important;
      }
    }
  }
}
@media (max-width: 1024px) {

  .userManagement{

    .userSalesBox{
      display: block;

      .Heading{

        h3{
          font-size: 28px;
        }
      }
    }
  }

  .tabbingSection{

    .tableResponsive{
  
      table{
        border-spacing: 0 7px;
  
        thead{
  
          tr{
  
            th{
              font-size: 15px;
              min-width: 90px;
            }
          }
        }
  
        tbody{
  
          tr{
            
            td{
              font-size: 12px;
  
              span{
                width: 24px;
                height: 24px;
  
                &.yelloIcon{
  
                  svg{
                    font-size: 18px;
                  }
                }
  
                &.greenIcon{
  
                  svg{
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}