$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -8px;
  font-size: 12px;
  color: $main-color;
}

.group {
  position: relative;
//   margin: 45px 0;

  .form-input {
    background: none;
    background-color: #F8F8F8;
    color: #808080;
    font-size: 18px;
    padding: 6px 16px 7px 16px;
    display: block;
    width: 100%;
    border: 1px solid #808080;
    border-radius: 45px;
    font-weight: 500;

    &:focus {
      outline: none;
     
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }  
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    color: #808080;
    font-size: 13px;
    background-color: #f8f8f8;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 50%;
    transform: translate(0%, -56%);
    transition: 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
