.bodyBackgound{
  background-color: #01243C;
  height: auto;
  padding-bottom: 20px;
}

.userManagement{

  .userSalesBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0px 40px;

    .Heading{

      h3{
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
      }
    }

    .totalUser{
      display: flex;
      align-items: end;
      gap: 25px;

      .boxUser{
        background-color: #fff;
        border-radius: 10px;
        border: 4px solid #FFFFFF;
        min-width: 159px;

        h4{
          color: #01243C;
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          text-align: center;
          padding: 12px 0px;    
          height: 52px;

          text{
            font-size: 12px;
            display: block;

            &.smallText{
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .userCount{
          background-color: #01243C;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          text-align: center;
          padding: 12px 0px;
          color: #fff;
          font-size: 22px;
          font-weight: 600;
          line-height: 18px;
          border-radius: 0px 0px 8px 8px;
        }

        ul{
          display: flex;
          align-items: center;
          text-align: center;

          li{
            list-style: none;

            &:last-child{

              span{
                border-right: none;
              }
            }

            &:last-child{

              h4{
                border-right: none;
              }
            }

            h4{
              color: #01243C;
              font-size: 15px;
              font-weight: 600;
              line-height: 18px;
              font-family: "Exo 2";
              font-style: italic;
              text-transform: uppercase;
              text-align: center;
              padding: 12px 8px;
              min-width: 90px;
              max-width: 90px;
              border-right: 2px solid #01243C;
            }

            span{
              background-color: #01243C;
              font-family: "Exo 2";
              font-style: italic;
              text-transform: uppercase;
              text-align: center;
              padding: 12px 0px;
              color: #fff;
              font-size: 22px;
              font-weight: 600;
              line-height: 18px;
              display: block;
              border-right: 2px solid #fff;
            }
          }
        }
      }

      h3{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        line-height: 15px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 6px;
      }
    }

    .filterRemove{
      text-align: right;

      span{
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        font-family: "Exo 2";
        font-style: italic;
        text-transform: uppercase;
        color: #fff;
        padding-bottom: 6px;
        display: inline-block;
        cursor: pointer;

        span{
          font-weight: 700;
        }
      }

      .filterRemove{
        position: relative;

        input{
          border-radius: 4px;
          border: 1px solid #FFFFFF99;
          background: #FFFFFF80;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #FFFFFF99;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          padding: 10px 10px 10px 25px;
          min-width: 300px;

          &::placeholder{
            color: #FFFFFF99;
          }

          &:focus{
            box-shadow: none;
            outline: none;
          }
        }

        button{
          position: absolute;
          right: 0px;
          height: 100%;
          border-radius: 4px;
          background-color: #fff;
          padding: 10px;
          color: #2E3848;
          font-family: "Exo 2";
          font-style: italic;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 700;
          border: 1px solid #fff;
          cursor: pointer;
        }
      }
    }
  }
}

.tabbingSection{

  .tableResponsive{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    // overflow: hidden;
    height: calc(100vh - 230px);
    // overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
  }

    table{
      border-collapse: separate;
      border-spacing: 0 10px;
      width: 100%;

      thead{
        position: sticky;
        top: 0px;

        tr{

          th{
            background: transparent;
            width: auto;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            font-family: "Exo 2";
            text-transform: uppercase;
            cursor: pointer;
            background-color: #01243C;

            .dropDownFilter {
              display: inline;

              &:global(.show){
                :global(.dropdown-menu){
                  display: block;
                }
              }
          
              :global(.dropdown-toggle) {
                  border: none;
                  background: transparent;
                  padding: 0;
                  position: relative;
          
                  &::after {
                      display: none;
                  }
                  svg {
                      color: #fff;
                      font-size: 18px;
                      position: absolute;
                      top: -15px;
                      right: -30px;
                  }
              }

              :global(.dropdown-menu){
                display: none;

                ul{
                  list-style: none;
                  background: #fff;
                  border-radius: 10px;
                  min-width: 140px;
                  color: #000;
                  padding: 10px 15px;

                  &.dateFilter{

                    li{

                      label{
                        display: block;
                        font-size: 15px;
                        font-weight: 700;
                        color: #01243c;
                        margin-bottom: 4px;
                      }

                      input{
                        width: 160px;
                        height: 41px;
                        padding: 0px 8px;
                        border: 2px solid #01243c;
                        border-radius: 6px;
                        font-size: 16px;
                        font-weight: 600;
                      }
                      button{
                        background-color: #01243c;
                        color: #fff;
                        padding: 10px 20px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 14px;
                        margin-top: 2px;
                        border: 1px solid #01243c;
                        cursor: pointer;

                        &:focus{
                          outline: none;
                          box-shadow: none;
                        }
                      }
                    }
                  }

                  li{
                    text-align: left;
                    padding: 5px 0px;
                  }
                }
              }
          }
          }
        }
      }

      tbody{

        tr{
          background: #15355A;
          cursor: pointer;
          
          td{
            border: none;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            font-family: "Exo 2";
            text-transform: uppercase;
            text-align: center;

            span{
              width: 30px;
              height: 30px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #fff;
              margin: 0 auto;

              &.yelloIcon{

                svg{
                  color: #FABA06;
                  font-size: 24px;
                }
              }

              &.greenIcon{

                svg{
                  color: #1CBC0F;
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  .userManagement{

    .userSalesBox{

      .totalUser{
        display: block;

        .boxUser{
          margin-bottom: 12px;

          ul{
            justify-content: space-between;

            li{
              width: 100% !important;
              min-width: auto !important;
              max-width: none !important;

              h4{
                width: 100% !important;
                min-width: auto !important;
                max-width: none !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dataTable {
    :global(.MuiDataGrid-toolbarContainer) {
      :global(.MuiFormControl-root) {
        margin-top: 10px;
      }
    }

    :global(.MuiDataGrid-root) {
      :global(.MuiDataGrid-virtualScroller) {
        max-height: calc(100vh - 310px) !important;
      }
    }
  }
}
@media (max-width: 1024px) {

  .userManagement{
    padding-top: 80px;

    .userSalesBox{
      display: block;
      padding: 10px 0px 5px;

      .Heading{

        h3{
          font-size: 28px;
        }
      }

      .totalUser{
        justify-content: end;
        margin-bottom: 12px;

        .boxUser{
          min-width: 130px;

          h4{
            font-size: 13px;   
            height: 46px;

            text{
              font-size: 9px;

              &.smallText{
                height: 26px;
              }
            }
          }

          .userCount{
            font-size: 19px;
          }

          ul{
            display: flex;
            align-items: center;
            text-align: center;

            li{

              h4{
                font-size: 13px;
                min-width: 70px;
                max-width: 70px;
                padding: 12px 2px;
              }

              span{
                font-size: 19px;
              }
            }
          }
        }

        h3{
          font-size: 16px;
        }
      }
    }
  }

  .tabbingSection{

    .tableResponsive{
  
      table{
        border-spacing: 0 7px;
  
        thead{
  
          tr{
  
            th{
              font-size: 15px;
              min-width: 130px;
            }
          }
        }
  
        tbody{
  
          tr{
            
            td{
              font-size: 12px;
  
              span{
                width: 24px;
                height: 24px;
  
                &.yelloIcon{
  
                  svg{
                    font-size: 18px;
                  }
                }
  
                &.greenIcon{
  
                  svg{
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}