.users {
  // min-height: 100vh;
  width: 100vw;
  background-color: #fff;
  color: white;
  position: fixed;
  z-index: 99;

  &__header {
    background: white;
    padding: 0.6rem 0px;
    display: flex;
    @media screen and (max-width: 750px) {
      padding: 0rem 3rem;
    }
    @media screen and (max-width: 750px) {
      padding: 0.5rem 3rem;
    }
    @media screen and (max-width: 600px) {
      padding: 0.5rem 2rem;
    }
    @media screen and (max-width: 500px) {
      padding: 0.7rem 0.9rem;
    }
    align-items: center;
    justify-content: space-between;
    &__logo {
      width: 120px;
      img {
        width: 100%;
      }
    }
    &__nav {
      display: flex;
      align-items: center;
      &-links {
        @media screen and (max-width: 700px) {
          display: none;
        }
        color: #01243C;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 500px;
        &-link {
          font-size: 18px;
          font-weight: 500;
          font-family: "Exo 2";
          text-transform: uppercase;
          &:first-child {
            margin-left: 0rem;
          }
        }
      }
      &__user {
        @media screen and (max-width: 700px) {
          display: none;
        }
        color: #01243C;
        display: flex;
        margin-left: 5rem;
        align-items: center;
        div {
          margin-left: 0.5rem;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
  &-container {
    padding: 4rem 3rem;
    width: 75vw;
    margin: auto;
    @media screen and (max-width: 1250px) {
      width: 85vw;
    }
    @media screen and (max-width: 1000px) {
      width: 90vw;
    }
    @media screen and (max-width: 830px) {
      width: 100%;
      padding: 4rem 1rem;
    }
  }
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-text {
      font-size: 2.7rem;
      text-transform: uppercase;
      font-style: italic;
      font-weight: bold;
      @media screen and (max-width: 750px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }
    &-button {
      border: 1px solid white;
      padding: 0.3rem 0.8rem;
      border-radius: 4px;
      cursor: pointer;
      background: white;
      color: #01243C;
      font-weight: bold;
      @media screen and (max-width: 750px) {
        padding: 0.2rem 0.7rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 1.1rem;
        padding: 0.17rem 0.65rem;
      }

      text-transform: uppercase;
      font-style: italic;
    }
  }
  &__contents {
    margin-top: 3rem;
    display: flex;
    &__items {
      background-color: #1f2735;
      width: 100%;
      overflow-x: auto;

      padding: 1.5rem;
      @media screen and (max-width: 550px) {
        padding: 0.7rem;
      }
      border-radius: 10px;
      &-headings {
        display: grid;
        grid-template-columns: 1fr 0.6fr 0.6fr 0.6fr 0.3fr 0.4fr 0.2fr 0.2fr 0.2fr;
        grid-auto-flow: column;
        gap: 1rem;
        width: 100%;

        font-size: 1.1rem;
        font-weight: 600;
        @media screen and (max-width: 750px) {
          font-size: 1rem;
          gap: 1.3rem;
        }
        @media screen and (max-width: 600px) {
          grid-template-columns: 90px 120px 120px 120px 40px 40px 40px 40px 40px;

          font-size: 1rem;
          // gap: 2rem;
        }
        font-style: italic;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(255, 255, 255, 0.662);
        padding-bottom: 0.6rem;
      }
      &-items {
        margin-top: 1rem;

        width: 100%;
      }
      &-item {
        display: grid;
        grid-template-columns: 1.1fr 0.6fr 0.6fr 0.8fr 0.4fr 0.2fr 0.2fr 0.2fr 0.2fr;
        width: 100%;

        @media screen and (max-width: 750px) {
          font-size: 0.9rem;
          gap: 1.3rem;
        }
        @media screen and (max-width: 600px) {
          grid-template-columns: 90px 90px 100px 120px 40px 40px 40px 40px 40px;
          font-size: 1rem;
          gap: 2rem;
          padding: 0.7 0.4;
          overflow-x: auto;
          width: fit-content;
        }
        padding: 0.8rem 0.6rem;
        border-radius: 2px;
        background-color: #12182169;
        margin-top: 1rem;
        // width: fit-content;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #0e141c79;
          transform: scale(1.001);
          cursor: pointer;
        }
      }
    }
  }

  .headerLogos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    
    .appLink{
      font-size: 18px;
      font-weight: 500;
      font-family: "Exo 2";
      text-transform: uppercase;
      color: #01243C;
    }
  }
}

.coupon__form {
  width: 100%;
  &-contents {
    padding: 0.3rem;
    &-input {
      margin-top: 1rem;
      &:first-child {
        margin-top: 0.2rem;
      }
    }
    .checkbox-inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 650px) {
        // flex-direction: column;
        // align-items: flex-start;
      }
    }
    .checkbox-input {
      display: flex;
      gap: 0rem;

      align-items: center;
      label {
        font-weight: 600;
        text-transform: uppercase;
        font-style: italic;
        margin-left: 2px;
      }
      input {
        appearance: none;
        height: 12px;
        width: 12px;
        position: relative;
        top: 1px;
        right: 14px;
        border: 1px solid #1f2735;
        &:checked {
          background-color: #1f2735;
        }
      }
    }
    &-button {
      text-align: center;
      margin-top: 1rem;
      padding: 0.7rem 0;
      background: #01243C;
      color: white;
      border-radius: 5px;
    }
  }
}

.users__header__nav__ham {
  display: none;
  @media screen and (max-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1f2735;
  }
}

.votes-table-container {
  display: flex;
  justify-content: space-between;
}


