.mysession {
    &-container {
        padding: 3rem;
        color: white;
        font-style: italic;
        text-transform: uppercase;
        @media screen and (max-width:1200px) {
            padding: 2rem 1rem;
        }
        @media screen and (max-width:700px) {
           padding: 2rem 0.5rem;
        }
        @media screen and (max-width:600px) {
           padding: 3rem 0.5rem;
        }
    }

    &-controller {
        width: 70%;
        margin: auto;
        @media screen and (max-width:1300px) {
            width: 80%;
        }
        @media screen and (max-width:1100px) {
            width: 90%;
        }
        @media screen and (max-width:500px) {
            width: 100%;
        }
        
    }
    &__contents {
        display: grid;
        margin-top: 3rem;
        height: 70vh;
        @media screen and (max-width:500px) {
            margin-top: 2rem;
            height: 75vh;
        }
        
        overflow-y: scroll;
        grid-template-columns: repeat(3,1fr);
        @media screen and (max-width:1070px) {
            grid-template-columns: repeat(2,1fr);
        }

        @media screen and (max-width:650px) {
            grid-template-columns: repeat(1,1fr);
            padding: 0 1rem;
         }
        grid-auto-flow: row;
        gap: 3rem;
        &-item {
            display: flex;
            align-items: center;
            font-weight: 700;
            justify-content: center;
            flex-direction: column;
            &-date {
                font-size: 1.1rem;
                @media screen and (max-width:1000px) {
                    font-size: 1rem;
                }
            }

            &-location {
                margin-top:  0.2rem;
                font-size: 1.1rem;
                @media screen and (max-width:1000px) {
                    font-size: 1rem;
                }
            }
            &-video {
                width: 250px;
                @media screen and (max-width:1300px) {
                    width: 230px;
                }
                @media screen and (max-width:767px) {
                    width: 100%;
                }
                margin-top: 0.4rem;
                height: 228px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 4rem;
                border-radius: 8px;
                color: #01243C;                
                // width: 234px;
                background: #C4C4C4;
            }
            &-session-title {
                margin-top: 0.4rem;
                margin-bottom: 0.4rem;
            }
            &-buttons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                justify-content: center;
                margin-top: 0.4rem;
                gap: 0.75rem;
                &-button {
                    background: white;
                    color: #01243C;
                    font-size: 0.8rem;
                    width: 100%;
                    font-weight: 700;
                    font-style: italic;
                    text-transform: uppercase;
                    padding: 0.7rem 0.5rem;
                    border: none;
                    border-radius: 5px;
                   cursor: pointer;
                }
            }
        }
    }
}

.SessionText{
    font-size: 30px;
    margin-top: 124px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-style: italic;
    text-transform: uppercase;
}


