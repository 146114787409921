.vstack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.profileContainer {
  max-width: 650px;
  margin-inline: auto;
}

.profileBox {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
    outline: 3px solid #dcdcdc;
  background: #fff;
  color: #01243c;

  @media screen and (min-width: 48rem) {
    border-radius: 24px;
  }
}

.profileBox.big {
  padding-block: 24px;
}

.profileBox .label {
  color: #01243c;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: none;
    gap: 3px;

  @media screen and (min-width: 48rem) {
    font-size: 1.5rem;
  }
}

.profileBox .label .small {
  font-size: 0.875rem;
  font-weight: 500;

  @media screen and (min-width: 48rem) {
    font-size: 1.125rem;
  }

}

.profileBox .label .medium {
  font-size: 1.25rem;
  font-weight: 600;
}

.profileBox .value {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: none;

  @media screen and (min-width: 48rem) {
    font-size: 1.875rem;
  }
}

.profileBox .value.active {
  font-weight: 600;
  text-transform: uppercase;
}

.profileBox .value .highlighted {
  color: #fbbc05;
}

.activeProfileInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 84px;
}

.activeProfileInnerContainer .details {
  align-items: stretch;

  gap: 16px;

  @media screen and (min-width: 48rem) {
    gap: 24px;
  }
}

.unsubscribeButton {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 10px;

  border-radius: 12px !important;
  border: 3.24px solid #da1414;
  background: #fbe7e7;
  font-family: "Exo 2", sans-serif;
  color: #da1614;
  text-align: center;
  font-size: .95rem;
  font-weight: 600;
}

.resubscribeButton {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 10px;

  border-radius: 12px !important;
  border: 3.24px solid #FABA06;
  background: #FABA06;
  font-family: "Exo 2", sans-serif;
  color: #01243C;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}
