.checkoutTitle {
    text-align: center;
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    padding-bottom: 12px ;

    @media (min-width: 60rem) {
        padding-bottom: 2.5rem ;
    }
}