.forgot-wrapper{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;

}

.main-forgot-container-wrapper{
    width: 100%w;
    height: 100vh;
    /* background-color: red; */
}